// Config files
import userConfig from '@/assets/config/user/user.conf'

// Services
import { eventEmit, debounce } from '@/services/utils/utils.service'
import { parseTemplate } from '@/services/dnd-template/dnd-template.service'
import {
  userConfigLoaded,
  landingPageMode,
  globalstates,
  mjmlOptimized,
  devmode,
  setDefaultStyle,
  buildDefaultStylesObject,
  setVersionId,
  getVersionId,
  setHtmlBlocksVersions,
  getHtmlBlocksVersions,
  convertColumns
} from '@/services/states/states.service'

import mjBodyConf from '@/assets/config/mjml-components/mj-body.conf.json'

// Libraries
import {
  get as _get,
  map as _map
} from 'lodash-es'

class Api {
  constructor (axios) {
    this._axios = axios

    // Object@resourcesUrl
    this._resourcesUrl = {
      // MjmlToHtml from MJML API
      toHtml: () => '/mjml_api/mjml/generate',
      // toHtml: () => 'http://0.0.0.0:3000/mjml/generate',
      // MJMLs
      mjmls: id => `/api/htmls/${id}/versions`,
      // Blocks (Predifined MJMLs rows)
      blocks: id => id ? `/api/blocks/${id}` : '/api/blocks',
      // Templates (Predifined MJMLs base)
      templates: id => id ? `/api/templates/${id}` : '/api/templates',
      // Images
      image: id => `/api/htmls/${id}/images`,
      // Client conf
      config: () => '/api/configs',
      blockVersions: id => `/api/blocks_versions/${id}`,
      htmlsBlocksVersions: id => `/api/htmls_versions/${id}/htmls_blocks_versions`
    }
    // Object@resourcesUrl
  }

  // Func@token
  /**
   * Authorization token modifier
   * @return {Methods} (get, update)
   */
  token () {
    // Methods
    return {
      /**
       * Get current authorization token
       * @return {String} (token)
       */
      get: () => {
        return this._axios.defaults.headers.common.Authorization
      },
      /**
       * Update authorization token
       * @param  {String} token (new token)
       */
      update: (token) => {
        this._axios.defaults.headers.common.Authorization = token
      }
    }
  }
  // Func@token

  // Func@clientConfig
  /**
   * Load client config
   * @return {Methods} (load)
   */
  clientConfig () {
    // Methods
    return {
      load: async () => {
        const url = this._resourcesUrl.config()

        if (userConfigLoaded().get()) return

        try {
          const response = await this._axios.get(url)
          const colors = _get(response.data, 'colors', [])
          const newFonts = _get(response.data, 'fonts', [])

          // Add client colors to user config file
          if (colors.length) {
            userConfig.colors.push(...colors)
          }

          if (newFonts.length && newFonts[0].name !== 'myFont') {
            const fonts = []
            newFonts.forEach(font => {
              const newFont = new FontFace(font.name, `url(${font.font_url}) format('${font.format}')`)
              fonts.push(
                newFont
                  .load()
                  .then(f => {
                    document.fonts.add(f)
                    userConfig.fonts.push(font)
                  }, err => {
                    throw err
                  })
              )
            })
            await Promise.all(fonts)

            document.fonts.ready.then(function () {
              console.info(document.fonts.size, 'FontFaces loaded', newFonts.length, 'are dynamic:')
              console.info(`%c ${_map(newFonts, 'name').join(', ')}`, 'color: #C87BEA;')
            })
          }

          // Config has been loaded
          userConfigLoaded().set(true)
        } catch (err) {
          console.error(err)
        }
      }
    }
  }
  // Func@clientConfig

  // Func@mjml
  /**
   * Mjml api endpoint methods
   * @param  {String} id (user id)
   * @return {Methods} (load, save, render)
   */
  mjml (id) {
    // Methods
    return {
      /**
       * Load MJML template depending id and parse it for DNDEDITOR
       * @return {Promise} (DND MJML)
       */
      load: async () => {
        // Func@mjml
        if (!id) {
          console.error('API service: Wrong parameter for load(), mjml(id) need an id')
          return
        }
        const url = this._resourcesUrl.mjmls(id)
        try {
          const response = await this._axios.get(url)
          const template = _get(response.data, 'mjml_json')
          const version = _get(response.data, 'id')
          const defaultStyle = _get(response.data, 'default_style')
          if (defaultStyle) {
            setDefaultStyle(JSON.parse(defaultStyle))
          } else {
            setDefaultStyle(globalstates.user.history.getStartingDefaultStyle())
          }
          if (!template) {
            console.warn('loadTemplate: loaded template not valid')
            return null
          }
          setVersionId(version)
          return parseTemplate({ template })
          .toDnd()
          .then(dndTemplate => {
            return dndTemplate
          })
        } catch (err) {
          console.error(err)
        }
        // Func@mjml
      },
      /**
       * Save DNDMJML to id reference
       * @param  {Object} template (DND MJML)
       * @return {Promise}
       */
      loadBlocksVersions: async () => {
         // Func@mjml
         if (!id) {
          console.error('API service: Wrong parameter for loadBlocksVersions(), mjml(id) need an id')
          return
        }
        const url = this._resourcesUrl.htmlsBlocksVersions(id)
        try {
          const response = await this._axios.get(url)
          setHtmlBlocksVersions(response.data)
          return response.data
        } catch (err) {
          console.error(err)
        }
      },
      updateHtmlsBlocksVersions: async () => {
        let blocks = getHtmlBlocksVersions() || []
        const apiBlocks = blocks.map((block) => {
          return {
            blocks_versions_id: block.blocks_versions_id,
            children_index: block.children_index,
          }
        })
        const url = this._resourcesUrl.htmlsBlocksVersions(getVersionId())
        try {
          const response = await this._axios.post(url, apiBlocks)
          return response
        } catch (err) {
          console.error(err)
        }
      },
      save: async (template, formOg) => {
        // Func@mjml
        if (!id) {
          console.error('API service: Wrong parameter for save(template), mjml(id) need an id')
          return
        }
        if (!template) {
          console.error('API service: Wrong parameter for save(template), a template is requested')
          return
        }
        const url = this._resourcesUrl.mjmls(id)
        eventEmit('update')

        const defaultStyles = buildDefaultStylesObject()
        const stringDefaultStyle = JSON.stringify(defaultStyles)

        // filter out saved blocks for mjml storage
        let cleanTemplate = Object.assign({}, JSON.parse(JSON.stringify(template)))
        cleanTemplate.children = cleanTemplate.children.map((child) => {
          if (child.tagName === 'mj-body') {
            child.children = child.children.filter(s => !s.block)
          }
          return child
        })
        // Create a new template for HTML generation
        let blocks = getHtmlBlocksVersions() || []

        const templatesWithBlocks = Object.assign({}, JSON.parse(JSON.stringify(template)))
        templatesWithBlocks.children[0].children.forEach((c, index) => {
          if (c.block) {
            const originalJSON = convertColumns( c.block.mjml_json)
            templatesWithBlocks.children[0].children.splice(index, 1, originalJSON)
          }
        })
        templatesWithBlocks.children[0].children = [].concat.apply([], templatesWithBlocks.children[0].children)

        const apiBlocks = blocks.map((block) => {
          return {
            blocks_versions_id: block.blocks_versions_id,
            children_index: block.children_index,
          }
        })
        const payload = {
          mjml_json: parseTemplate({ template: cleanTemplate, formOg }).toMjml({ toString: true }),
          default_style: stringDefaultStyle
        }
        try {
          const response = await this._axios.post(url, payload)
          setVersionId(response.data.id)
        } catch (err) {
          if (err.response.status === 401) {
            eventEmit('save-unauthorized')
          } else {
            eventEmit('save-failed')
          }
        } finally {
          try {
            await this._axios.post(this._resourcesUrl.htmlsBlocksVersions(getVersionId()), apiBlocks)
          } catch (err) {
            eventEmit('blocks-versions-save-failed')
          } finally {
            try {
              const data = {
                mjml_JSON: parseTemplate({ template: templatesWithBlocks }).toMjml({ toString: false }),
                devmode: mjmlOptimized().get() === false,
                disableMinify: false,
                disableMinifyCss: false,
                disableRemoveEmptyAttributes: false
              }
              const response = await this._axios.post(this._resourcesUrl.toHtml(), data)
              eventEmit('saved', response.data.html)
            } catch(err) {
              console.error(err)
              eventEmit('generate-html-failed')
            }
          }
        }
        // Func@mjml
      },
      /**
       * Call directly MJML microservice function MJMLtoHTML
       * @param  {Object} template (MJML template)
       * @return {Promise}         (HTML)
       */
      render: async (template, disableParsing) => {
        // Func@mjml
        const url = this._resourcesUrl.toHtml()
        const payload = {
          mjml_JSON: disableParsing ? template : parseTemplate({ template }).toMjml({ toString: false }),
          devmode: mjmlOptimized().get() === false,
          disableMinify: false,
          disableMinifyCss: false,
          disableRemoveEmptyAttributes: false
        }

        // No request mjml directly if not in devmode
        if (!devmode().get() || !url) return

        try {
          const response = await this._axios.post(url, payload)
          const errors = response.data.errors
          const hasErrors = Object.entries(errors).length !== 0
          if (hasErrors) {
            const message = errors.reduce((obj, item) => {
              obj[item.tagName] = item.message
              return obj
            }, {})
            debounce({
              type: 'logMjmlErrors',
              func: function (errors) {
                console.warn('MJML WARNING:')
                console.table(errors)
              },
              params: [message]
            })
          }
          return response.data.html
        } catch (err) {
          console.error(err)
        }
        // Func@mjml
      }
    }
  }
  // Func@mjml

  // Func@blocks
  /**
   * Blocks api endpoint methods
   * @param  {String} id (block id)
   * @return {Methods} (load, save, update)
   */
  blocks (id) {
    /**
     * Block model
     * @param  {Object} rawData (API definition)
     * @return {Object}         (Interface definition)
     */
    const baseMjml =  {
      tagName: 'mjml',
      attributes: {},
      children: [
        {
          tagName: 'mj-body',
          attributes: _get(mjBodyConf, 'attributes.default', {}),
          children: []
        },
        {
          tagName: 'mj-head',
          attributes: {},
          children: []
        }
      ]
    }

    // Methods
    return {
      lastVersion: async() => {
        const url = this._resourcesUrl.blocks(id) + '/blocks_versions'
        try {
          const params = {
            l: 1,
            ob: '-created_at'
          }
          const response = await this._axios.get(url, { params: params })
          const blockVersion = _get(response.data, 'objects')
          const basicMjml = Object.assign({}, baseMjml)
          if (blockVersion.length > 0) {
            basicMjml.children[0].children = JSON.parse(blockVersion[0].mjml_json)
          }
          const version = await parseTemplate({ template: basicMjml }).toDnd()
          return {
            version
          }
        } catch (err) {
          // no version
          const basicMjml = Object.assign({}, baseMjml)
          return { version: await parseTemplate({ template: basicMjml }).toDnd() }
        }
      },
      getVersion: async(versionId) => {
        const url = this._resourcesUrl.blockVersions(versionId)
        try {
          const response = await this._axios.get(url)
          const version = response.data
          return version
        } catch (err) {
          console.error(err)
        }
      },
      postVersion: async ({ block }) => {
        // Func@blocks
        try {
          const url = this._resourcesUrl.blocks(id) + '/blocks_versions'
          const mjml_json = await parseTemplate({ template: block }).toMjml({ toString: true })
          const response = await this._axios.post(url, {
            mjml_json: JSON.stringify(JSON.parse(mjml_json).children[0].children)
          })
          eventEmit('block-saved', { isSuccess: true })
          return response
        } catch (err) {
          eventEmit('block-saved', { isSuccess: false })
          console.error(err)
        }
        // Func@blocks
      },
      /**
       * Load blocks
       * @param  {String} options.s  (Search query)
       * @param  {Integer} options.p (Page number)
       * @param  {Integer} options.l (Page size)
       * @param  {String} options.groupId (Page size)
       * @return {Promise}           ({ blocks, total })
       */
      load: async ({ s, p, l, groupId }) => {
        // Func@blocks
        const url = this._resourcesUrl.blocks()
        try {
          const params = {
            p, l
          }
          params.s = s.length > 0 ? s : null
          params.content_type = landingPageMode().get() ? 2 : 1
          params.group_ids = groupId
          params.has_versions = true
          const response = await this._axios.get(url, { params: params })
          const blocks = _get(response.data, 'objects')

          return {
            total: _get(response.data, 'meta.total', 0),
            blocks
          }
        } catch (err) {
          console.error(err)
        }
        // Func@blocks
      },

      loadMjml: async() => {
        const url = this._resourcesUrl.blocks(id) + '/blocks_versions'
        try {
          const params = {
            l: 1,
            ob: '-created_at'
          }
          const response = await this._axios.get(url, { params: params })
          const blockVersion = _get(response.data, 'objects')
          if (blockVersion.length > 0) {

            const basicMjml = Object.assign({}, baseMjml)
            if (blockVersion.length > 0) {
              basicMjml.children[0].children = JSON.parse(blockVersion[0].mjml_json)
            }
            const version = await parseTemplate({ template: basicMjml }).toDnd()
            return { mjml_json: version.children[0].children, id: blockVersion[0].id, block_id: blockVersion[0].block_id }
          }
        } catch (err) {
          // no version
          return { no_version: true }
        }
      },
      loadBlock: async() => {
        const url = this._resourcesUrl.blocks(id)
        try {
          const params = {
            l: 1,
            ob: '-created_at'
          }
          const response = await this._axios.get(url, { params: params })
          return response.data
        } catch (err) {
          // no version
          return { no_version: true }
        }
      },
      /**
       * Save a new block
       * @param  {String} options.name  (Block name)
       * @param  {Object} options.block (List of mj-section)
       * @return {Promise}
       */
      save: async ({ name, block, group_ids }) => {
        // Func@blocks
        try {
          const url = this._resourcesUrl.blocks()
          const response = await this._axios.post(url, {
            name: name,
            content_type: landingPageMode().get() ? 2 : 1,
            group_ids
          })
          if (response) {
            try {
              const basicMjml = Object.assign({}, baseMjml)
              basicMjml.children[0].children = block
              let urlVersion = this._resourcesUrl.blocks(response.data.id) + '/blocks_versions'
              const mjml_json = await parseTemplate({ template: basicMjml }).toMjml({ toString: true })
              await this._axios.post(urlVersion, {
                mjml_json: JSON.stringify(JSON.parse(mjml_json).children[0].children)
              })
              eventEmit('block-saved', { isSuccess: true })
            } catch(err) {
              eventEmit('block-saved', { isSuccess: false })
              console.error(err)
            }
          }
        } catch (err) {
          eventEmit('block-saved', { isSuccess: false })
          console.error(err)
        }
        // Func@blocks
      },
      /**
       * Update a block
       * @param  {String} options.name (Block name)
       * @return {Promise}
       */
      update: async ({ name }) => {
        // Func@blocks
        if (!id) {
          console.error('API service: Wrong parameter for update({ name }), blocks(id) need an id')
          return
        }
        try {
          const url = this._resourcesUrl.blocks(id)
          await this._axios.patch(url, {
            block_name: name,
            content_type: landingPageMode().get() ? 2 : 1
          })
        } catch (err) {
          console.error(err)
        }
        // Func@blocks
      },
      /**
       * Remove an existing template from a group
       */
      remove: async () => {
        // Func@templates
        try {
          const url = this._resourcesUrl.blocks(id) + '/groups'
          await this._axios.delete(url)
          eventEmit('block-removed', { isSuccess: true })
        } catch (err) {
          eventEmit('block-removed', { isSuccess: false })
          console.error(err)
        }
        // Func@templates
      }
    }
  }
  // Func@blocks

  // Func@templates
  /**
   * Templates api endpoint methods
   * @param  {String} id (template id)
   * @return {Methods} (load, save, update)
   */
  templates (id) {
    /**
     * Template reference model (item in list)
     * @param  {Object} rawData (API definition)
     * @return {Object}         (Interface definition)
     */
    function _model (rawData) {
      return {
        id: rawData.id,
        label: rawData.template_name,
        shared: rawData.shared
      }
    }

    // Methods
    return {
      /**
       * Load a list of templates or an existing one
       * @return {Promise}  (MJML Template / Template references list)
       */
      load: async () => {
        // Func@templates
        try {
          let url, response
          if (id) {
            url = this._resourcesUrl.templates(id)
            response = await this._axios.get(url)

            const defaultStyle = _get(response.data, 'default_style')
            if (defaultStyle) {
              setDefaultStyle(JSON.parse(defaultStyle))
            } else {
              setDefaultStyle(globalstates.user.history.getStartingDefaultStyle())
            }
            const params = {
              template: response.data.mjml_json,
              content_type: landingPageMode().get() ? 2 : 1
            }

            return parseTemplate(params)
              .toDnd()
              .then(dndTemplate => {
                return dndTemplate
              })
          }

          url = this._resourcesUrl.templates()
          response = await this._axios.get(url, {
            params: {
              p: 0,
              l: 150,
              content_type: landingPageMode().get() ? 2 : 1,
              group_resources: true
            }
          })

          return response.data.objects.map(template => {
            return _model(template)
          })
        } catch (err) {
          console.error(err)
        }
        // Func@templates
      },
      /**
       * Save a new template
       * @param  {String} options.name (template name)
       * @param  {Object} options.mjml (template structure)
       * @return {Promise}             (Saved template reference)
       */
      save: async ({ name, mjml }) => {
        // Func@templates
        try {
          const url = this._resourcesUrl.templates()
          const defaultStyles = buildDefaultStylesObject()
          const stringDefaultStyle = JSON.stringify(defaultStyles)
          const templatesWithBlocks = Object.assign({}, JSON.parse(JSON.stringify(mjml)))
          templatesWithBlocks.children[0].children.forEach((c, index) => {
            if (c.block) {
              let json = typeof c.block.mjml_json === 'string' ? JSON.parse(c.block.mjml_json) : c.block.mjml_json
              const originalJSON = convertColumns(json)
              templatesWithBlocks.children[0].children.splice(index, 1, originalJSON)
            }
          })
          templatesWithBlocks.children[0].children = [].concat.apply([], templatesWithBlocks.children[0].children)
          const response = await this._axios.post(url, {
            template_name: name,
            content_type: landingPageMode().get() ? 2 : 1,
            mjml_json: parseTemplate({ template: templatesWithBlocks }).toMjml({ toString: true }),
            default_style: stringDefaultStyle
          })
          eventEmit('template-saved', { isSuccess: true })
          return _model(response.data)
        } catch (err) {
          console.error(err)
          eventEmit('template-saved', { isSucces: false })
        }
        // Func@templates
      },
      /**
       * Update an existing template
       * @param  {String} options.name (template name)
       * @param  {Object} options.mjml (template structure)
       * @return {Promise}             (Updated template reference)
       */
      update: async ({ name, mjml }) => {
        // Func@templates
        try {
          const url = this._resourcesUrl.templates(id)
          const defaultStyles = buildDefaultStylesObject()
          const stringDefaultStyle = JSON.stringify(defaultStyles)

          const templatesWithBlocks = Object.assign({}, JSON.parse(JSON.stringify(mjml)))
          templatesWithBlocks.children[0].children.forEach((c, index) => {
            if (c.block) {
              let json = typeof c.block.mjml_json === 'string' ? JSON.parse(c.block.mjml_json) : c.block.mjml_json
              const originalJSON = convertColumns(json)
              templatesWithBlocks.children[0].children.splice(index, 1, originalJSON)
            }
          })
          templatesWithBlocks.children[0].children = [].concat.apply([], templatesWithBlocks.children[0].children)

          const response = await this._axios.patch(url, {
            template_name: name,
            content_type: landingPageMode().get() ? 2 : 1,
            mjml_json: parseTemplate({ template: templatesWithBlocks }).toMjml({ toString: true }),
            default_style: stringDefaultStyle
          })
          eventEmit('template-updated', { isSuccess: true })
          return _model(response.data)
        } catch (err) {
          console.error(err)
          eventEmit('template-updated', { isSuccess: false })
        }
        // Func@templates
      },
      /**
       * Remove an existing template from a group
       */
      remove: async () => {
        // Func@templates
        try {
          const url = this._resourcesUrl.templates(id) + '/groups'
          await this._axios.delete(url)
          eventEmit('template-removed', { isSuccess: true })
        } catch (err) {
          eventEmit('template-removed', { isSuccess: false })
          console.error(err)
        }
        // Func@templates
      }
    }
  }
  // Func@templates
}

export default Api
