// States & Config
import states from './template-content.state.js'
import workspaceStates from '@/components/parts/workspace/workspace.state.js'

// Services
import { applyDrag, debounce } from '@/services/utils/utils.service'
import { getStyles, getDefaultStyles } from '@/services/dnd-style-engine/dnd-style-engine.service'
import { globalstates, openEditPanel, closeEditPanel, dndState } from '@/services/states/states.service'

// Components
import { Container, Draggable } from 'vue-smooth-dnd'
import MdiClose from 'vue-material-design-icons/Close.vue'
import MdiCloseCircleOutline from 'vue-material-design-icons/CloseCircleOutline.vue'
import MdiEdit from 'vue-material-design-icons/SquareEditOutline.vue'
import MdiDelete from 'vue-material-design-icons/Delete.vue'
import MdiDuplicate from 'vue-material-design-icons/ContentDuplicate.vue'
import MdiChevronRight from 'vue-material-design-icons/ArrowRightBoldCircleOutline.vue'
import MdiChevronLeft from 'vue-material-design-icons/ArrowLeftBoldCircleOutline.vue'
import MdiMenu from 'vue-material-design-icons/Menu.vue'
import WorkspaceSelector from '@/components/standalone/workspace-selector/Workspace-selector.vue'

// Assets
import image from '@/assets/images/placeholder-image.jpg'
import qrcode from '@/assets/images/placeholder-qrcode.png'
import video from '@/assets/images/placeholder-video.svg'

// Library
import {
  get as _get,
  uniqueId as _uniqueId,
  findIndex as _findIndex,
  cloneDeep as _cloneDeep
} from 'lodash-es'

/**
 * Vue declaration ------------------------------------
 */

// Name
const name = 'Template-content'

// Properties
const props = {
  data: Array,
  mode: String,
  mobile: Boolean,
  isNested: Boolean,
  parentData: Object,
  parentOnDrag: Boolean,
  keepContentActive: Boolean,
  parentSelectorHover: Boolean
}

// Vue@data
const data = function () {
  return {
    workspace: _get(globalstates, 'workspace'),
    leftPanel: _get(globalstates, 'leftPanel', {}),
    template: _get(globalstates, 'template', {}),
    workspaceStates,
    states,
    image,
    customBg: null
  }
}
// Vue@data

// Vue@subComponents
const components = {
  NestedSection: () => import('@/components/standalone/template-structure/Template-structure.vue'),
  Container,
  Draggable,
  WorkspaceSelector,
  MdiChevronRight,
  MdiChevronLeft,
  MdiDuplicate,
  MdiDelete,
  MdiClose,
  MdiCloseCircleOutline,
  MdiEdit,
  MdiMenu
}
// Vue@subComponents

// Methods
const methods = {

  // Func@onNestedSectionResize
  /**
   * Check on nested section resize if nested columns have frozen width,
   * if yes, automaticaly reset the columns on fluid mode
   * @param {Object} event (from resize directive)
   * @param {Object} mjml (nested section to check)
   */
  onNestedSectionResize (event, mjml) {
    const editPanelOpen = _get(this.leftPanel, 'editPanel.active')
    const bodyAttr = _get(this.workspaceStates, 'template.children.0.attributes', {})
    const bodyWidth = bodyAttr.width.split('px').shift()
    const viewportWidth = parseInt(window.innerWidth)
    const leftPanWidth = 350
    const margeWidth = 160
    const isWindowResize = viewportWidth < parseInt(bodyWidth) + leftPanWidth + margeWidth

    if (!this.is(mjml, 'mj-section') || this.isMobile || isWindowResize || editPanelOpen) return

    const nestedSectionWidth = event.detail.width
    const columnsWidth = mjml.children.map(c => {
      const widthAttr = c.attributes.width || ''
      return widthAttr.includes('px') ? parseInt(c.attributes.width) : c.attributes.width
    })

    if (typeof columnsWidth[0] !== 'number') return
    const sum = columnsWidth.reduce((a, b) => a + b, 0)

    if (sum !== nestedSectionWidth) mjml.children.forEach(c => { c.attributes.width = '' })
  },
  // Func@onNestedSectionResize

  // Func@getStyles
  /**
   * Get structure MJML style
   * @param  {Object} content    (MJML object)
   * @param  {String} parentData (Column data)
   *
   * @return {String}         (CSS style)
   */
  getStyles ({ element, parentData }) {
    return getStyles(element, parentData)
  },
  // Func@getStyles

  getComponentName (content) {
    let dndComponentId
    const contentClass = _get(content, 'attributes.css-class', '')
    const dndComponentIdClass = contentClass.split(' ').find(c => c.includes('dcid_'))
    if (dndComponentIdClass) dndComponentId = dndComponentIdClass.split('dcid_').pop()

    return dndComponentId ? `${content.tagName}-${dndComponentId}` : content.tagName
  },

  // Func@getSelectorOverlaid
  /**
   * Activate selector overlay when another content is on edit
   * @param  {Integer} index (selector position)
   * @return {Boolean}
   */
  getSelectorOverlaid (index) {
    const dragState = this.workspace.drag
    const isContentOnDrag = dragState.level === 'content' || dragState.level === 'content-new'
    const editPanelIsActive = _get(this.leftPanel, 'editPanel', {}).active
    const isNotContentOnEdit = !this.isOnEdit(index)
    const hasNoParentOnEdit = !this.keepContentActive
    const notOnEdit = editPanelIsActive && isNotContentOnEdit && hasNoParentOnEdit
    const notOnDrag = dragState.active && !this.parentOnDrag && !isContentOnDrag

    return notOnEdit || notOnDrag
  },
  // Func@getSelectorOverlaid

  // Func@buildSelectorsButtons
  /**
   * Get buttons list for selectors
   * @param  {Integer} index (current index)
   * @param  {Array}   actions (Authorized actions)
   * @return {Array}          (Actions list)
   */
  buildSelectorsButtons (actions, index) {
    const buttons = []
    const hasCloseAction =
      _get(this.leftPanel, 'editPanel.active') &&
      _get(this.leftPanel, 'editPanel.id') === this.data[index].id

    if (!hasCloseAction && actions.includes('edit')) {
      buttons.push({
        class: 'edit',
        icon: 'mdi-edit',
        action: this.openEdit
      })
    }
    if (!hasCloseAction && actions.includes('duplicate')) {
      buttons.push({
        class: 'duplicate',
        icon: 'mdi-duplicate',
        action: this.duplicateContent
      })
    }
    if (!hasCloseAction && actions.includes('delete')) {
      buttons.push({
        class: 'delete',
        icon: 'mdi-delete',
        action: this.deleteContent
      })
    }

    return buttons
  },
  // Func@buildSelectorsButtons

  // Func@onActionClick
  /**
   * Event mediator for selector actions
   * @param  {String}  actionType (action name)
   * @param  {Integer} elemIndex  (element index)
   * @param  {Object}  elem       (element)
   */
  onActionClick (actionType, elemIndex, elem) {
    switch (actionType) {
      case 'edit': {
        this.openEdit(elemIndex)
        break
      }
      case 'copy': {
        this.duplicateContent(elemIndex, elem)
        break
      }
      case 'delete': {
        this.deleteContent(elemIndex)
        break
      }
    }
  },
  // Func@onActionClick

  // Func@getPayload
  /**
   * Get DND payload
   * @param  {Number} index (position)
   *
   * @return {Object}       (payload)
   */
  getPayload (index) {
    return this.data[index]
  },
  // Func@getPayload

  // Func@onDrop
  /**
   * To get the droped list and replace the current
   * @param  {Object} dropResult (D&D params)
   */
  onDrop (dropResult) {
    const newArray = applyDrag(this.data, dropResult)
    this.data.splice(0, this.data.length, ...newArray)
  },
  // Func@onDrop

  // Func@selectorActions
  /**
   * Defining actions available for the current selector
   * @return {Object}
   */
  selectorActions (elem) {
    const editPanelIsActive = _get(this.leftPanel, 'editPanel', {}).active
    const actions = editPanelIsActive && !this.isNested ? [] : ['drag']
    const hasMjScratchIn = JSON.stringify(elem).includes('"tagName":"mj-scratch"')

    actions.push('edit')
    if (!hasMjScratchIn) actions.push('copy')
    actions.push('delete')

    return actions
  },
  // Func@selectorActions

  // Func@is
  /**
   * Check if the tagname corresponding the type
   * @param  {Object}  element (MJML element)
   * @param  {String}  type    (waited type)
   *
   * @return {Boolean}
   */
  is (element, type) {
    let dndComponentId
    let tagname = element.tagName
    const contentClass = _get(element, 'attributes.css-class', '')
    const dndComponentIdClass = contentClass.split(' ').find(c => c.includes('dcid_'))
    if (dndComponentIdClass) {
      dndComponentId = dndComponentIdClass.split('dcid_').pop()
      tagname = `${tagname}#${dndComponentId}`
    }
    return tagname === type
  },
  // Func@is

  /**
   * Check if content is hover and emit value
   * @param  {Boolean} value
   */
  contentIsHover (value, element) {
    const tag = this.getComponentName(element)
    const position = _findIndex(this.displayedContents, elem => elem.id === element.id)
    debounce({
      type: `selectors_${this.parentData.mjml.id}`,
      service: this,
      func: '$emit',
      params: ['contentSelectorHover', { value, tag, position }],
      delay: 50
    })
    this.states.contentIsHover.value = value
    this.states.contentIsHover.position = value ? position : null
  },

  // Func@onDrag
  /**
   * Used to set on-drag-true or on-drag-false class
   * @param  {Boolean} value
   */
  onDrag ({ isSource, payload, value }) {
    // Set drag and drop global state
    if (!isSource) return
    const componentId = _get(payload, 'tagName')
    const context = { isContent: true }
    dndState({ isSource, componentId, value, context })
  },
  // Func@onDrag

  // Func@deleteContent
  /**
   * Remove selected conetnt from the data
   * @param  {Number}   index  (Position)
   */
  deleteContent (index) {
    this.data.splice(index, 1)
  },
  // Func@deleteContent

  // Func@duplicateContent
  /**
   * Duplicate the current structure, refresh id, and add the
   * clone at the next index
   * @param  {Integer} index (Index of the element to duplicate)
   * @param  {Object}  elem  (Element to duplicate)
   */
  duplicateContent (index, elem) {
    const newContent = _cloneDeep(elem)
    newContent.id = _uniqueId(`${newContent.tagName}_`)
    this.data.splice(index + 1, 0, newContent)
  },
  // Func@duplicateContent

  // Func@openEdit
  /**
   * Open edit panel
   * @param  {Number} index (Position)
   */
  openEdit (index) {
    openEditPanel({
      parent: this.parentData,
      contents: this.data,
      index
    })
  },
  // Func@openEdit

  // Func@closeEdit
  /**
   * Close edit panel
   */
  closeEdit () {
    closeEditPanel()
  },
  // Func@closeEdit

  // Func@isOnEdit
  /**
   * Get wrapper class to define edit mode
   * @param  {Number} index (Position)
   *
   * @return {String}       (Class)
   */
  isOnEdit (index) {
    const editPanel = _get(this.leftPanel, 'editPanel', {})
    if (!editPanel.active) return

    const editPanelIsActive = editPanel.active && editPanel.index === index
    const isSameContent = this.data[index].id === _get(editPanel, `contents[${index}].id`)

    return editPanelIsActive && isSameContent
  },
  // Func@isOnEdit

  // Func@displayState
  /**
   * @param  {[type]} conf  [description]
   * @param  {[type]} index [description]
   *
   * @return {Boolean}
   */
  displayState (conf, index) {
    return conf.all || conf.index === index
  },
  // Func@displayState

  // Func@_checkImageSource
  /**
   * Generate image with correct size
   * @param {String} src (url)
   */
  _checkImageSource (id, src) {
    const img = new Image()
    img.onload = () => {
      this.$set(this.states.imagesOnError, id, false)
    }
    img.onerror = () => {
      this.$set(this.states.imagesOnError, id, true)
    }
    img.src = src
  },
  // Func@_checkImageSource

  // Func@imageUrl
  /**
   * Get image URL when this one isn't on error
   * @param  {Object} panel (Panel)
   *
   * @return {String}       (src || placeholder)
   */
  imageUrl (el, type) {
    if (!el) return
    const id = el.id
    const src = this.is(el, 'mj-video') ? el.attributes["image-src"] : el.attributes.src
    const types = {
      image,
      qrcode,
      video
    }
    this._checkImageSource(id, src)
    return this.states.imagesOnError[id] || !src ? types[type] : src
  },
  // Func@imageUrl

  getScratchOverlayClass (attr) {
    this.customBg = !attr.predefinedOverlay ? attr.srcOverlay : null
    return attr.predefinedOverlay ? attr.predefinedOverlay.toLowerCase() : 'custom'
  },

  /**
   * Get custom class for current element
   * @param  {Object}  element  (Element to check)
   *
   * @returns {String} (classes)
   */
  getCustomClass (element) {
    switch (element.tagName) {
      case 'mj-image': {
        const isMobileMode = this.workspace.mode === 'mobile'
        const isFluidOnMobile = element.attributes['fluid-on-mobile']
        if (isMobileMode && isFluidOnMobile) return 'width-100'
        break
      }
    }
  },

  /**
   * Binary if a content not in a first place is hover
   * @param {Integer} index (position)
   * @returns {Boolean}
   */
  isHoverNotFirst (index) {
    // if (this.states.forceOverflow) return false
    const isSameIndex = this.states.contentIsHover.position === index
    const isNotFirst = this.states.contentIsHover.position > 0
    return this.states.contentIsHover.value && isNotFirst && index > 0 && isSameIndex
  },


  /**
   * Get video element when this one isn't on error
   * @param  {String}  src (video src)
   *
   * @return {String}
   */
   getVideoExtension (src) {
    if (!src || !src.length) return ''

    const allowedExtensions = [
      'mp4',
      'webm',
      'ogg'
    ]
    try {
      var url = new URL(src);

      if (url.protocol !== "http:" && url.protocol !== "https:") {
        throw new Error();
      }
    } catch (_) {
      return ''
    }
    const extension = src.split('.').pop()
    return allowedExtensions.includes(extension) ? extension : ''
  }
}

// Computed methods
const computed = {
  // Func@getDragName
  /**
   * Generate group name
   * @returns {String} group name
   */
  getDragName () {
    const dragState = this.workspace.drag
    const isNestedStruct = dragState.level === 'content-structure' || dragState.level === 'section-new'

    switch (true) {
      case isNestedStruct && !this.isNested: {
        return `mj-${dragState.level}`
      }
      case isNestedStruct && this.isNested: {
        return 'no-drop'
      }
      default:
        return 'content'
    }
  },
  // Func@getDragName

  // Func@displayedContents
  /**
   * Check if component exist but is disabled for the current display
   * @returns {Boolean}
   */
  displayedContents () {
    return this.data.filter(content => {
      const cssClass = content.attributes['css-class']
      const isFallBackComponent = cssClass && cssClass.includes('fallback-')
      const isDisabledComponent = !this.isMobile && cssClass && cssClass.includes('fallback-desktop-false') ||
                                   this.isMobile && cssClass && cssClass.includes('fallback-mobile-false')
      return !isFallBackComponent || !isDisabledComponent
    })
  },
  // Func@displayedContents

  // Func@lockDrop
  /**
   * Binary to lock drop
   * @returns {Boolean}
   */
  lockDrop () {
    return this.getDragName === 'no-drop' && this.data.length === 0
  },
  // Func@lockDrop

  // Func@scratchCssVars
  /**
   * Get scracth css var
   * @returns {Object} list of scratch css var
   */
  scratchCssVars () {
    return {
      '--bg-img': 'url(' + this.customBg + ')'
    }
  },
  // Func@scratchCssVars

  // Func@isMobile
  /**
   * If it's mobile from url or resize
   */
  isMobile () {
    return this.mobile || this.workspace.mode === 'mobile'
  },
  // Func@isMobile

  // Func@getLinksStyles
  /**
   * Get links default style
   * @return {String} (Links CSS properties)
   */
  getLinksStyles () {
    return getDefaultStyles().links()
  },
  // Func@getLinksStyles

  // Func@getSelectorMoveElement
  /**
   * Get move selector css class
   * @return {String}
   */
  getSelectorMoveElement () {
    return '.workspace-selector.content, .workspace-selector.section.nested'
  },
  // Func@getSelectorMoveElement

  // Func@getComponentClass
  /**
   * Generate component class for type (section|column), mobile (false|true),
   * if an elem of the list is on drag with the Type of the elem on drag
   *
   * @return {String}  (class)
   */
  getComponentClass () {
    const isEmpty = this.displayedContents.length === 0
    const dragState = this.workspace.drag
    const editPanel = _get(this.leftPanel, 'editPanel', {})
    const isNestedStructureOnDrag = dragState.level === 'section-new' || dragState.level === 'content-structure'
    const isContentOnDrag = dragState.level === 'content-new' || dragState.level === 'content'

    return `
      mode-${this.mode}
      on-edit-${editPanel.active}
      is-empty-${isEmpty}
      ${this.keepContentActive ? 'keep-content-active' : ''}
      ${dragState.active && isNestedStructureOnDrag ? 'structure-on-drag' : ''}
      ${dragState.active && isContentOnDrag ? 'content-on-drag' : ''}
      ${this.isNested ? 'nested' : ''}`
  },
  // Func@getComponentClass

  // Func@lockDependingMode
  /**
   * Lock the DnD drag area depending mode
   *
   * @return {String} (class selector for non drag area)
   */
  lockDependingMode () {
    switch (true) {
      case _get(this.leftPanel, 'editPanel.active'):
      case this.mode === 'structure': return '.template-content'
      case this.mode === 'content': return ''
    }
  },
  // Func@lockDependingMode

  // Func@lockDrag
  /**
   * Lock the DnD drag area depending mode
   *
   * @return {String} (class selector for non drag area)
   */
  lockDrag () {
    return _get(this.leftPanel, 'editPanel.active') ? '.template-content' : ''
  },
  // Func@lockDrag
}

// Vue component syntax
export default {
  name,
  data,
  props,
  methods,
  computed,
  components
}
