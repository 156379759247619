// States & Config
import states from './workspace-selector.state.js'

// Components
import MdiEdit from 'vue-material-design-icons/Pencil.vue'
import MdiDelete from 'vue-material-design-icons/DeleteOutline.vue'
import MdiMove from 'vue-material-design-icons/CursorMove.vue'
import MdiCopy from 'vue-material-design-icons/ContentCopy.vue'
import MdiNoDesktop from 'vue-material-design-icons/MonitorOff.vue'
import MdiNoMobile from 'vue-material-design-icons/CellphoneOff.vue'
import MdiLinkOff from 'vue-material-design-icons/LinkOff.vue'
import MdiUpdate from 'vue-material-design-icons/Update.vue'
import MdiPencilBox from 'vue-material-design-icons/PencilBox'


// Library
import {
  cloneDeep as _cloneDeep,
  get as _get
} from 'lodash-es'

// Css variable
import {
  selectorNestedSectionBorder,
  selectorNestedSectionHeader,
  selectorSectionBorder,
  selectorSectionHeader,
  selectorColumnBorder,
  selectorColumnHeader,
  selectorContentBorder,
  selectorContentHeader,
  selectorSavedBlockBorder,
  selectorSavedBlockHeader,
} from '@/assets/styles/variables.scss'

/**
 * Vue declaration ------------------------------------
 */

// Name
const name = 'Workspace-selector'

// Vue@Properties
const props = {
  mode: {
    type: String,
    required: false,
    default: () => 'standard'
  },
  isNestedSection: {
    type: Boolean,
    required: false,
    default: () => false
  },
  isNestedElement: {
    type: Boolean,
    required: false,
    default: () => false
  },
  isChecked: {
    type: Boolean,
    required: false,
    default: () => false
  },
  hideNoContent: {
    type: Boolean,
    required: false,
    default: () => false
  },
  tag: {
    type: String,
    required: false,
    default: () => null
  },
  screenModeFifty: {
    type: Boolean,
    required: false,
    default: () => false
  },
  editActive: {
    type: Boolean,
    required: false,
    default: () => false
  },
  dragActive: {
    type: Boolean,
    required: false,
    default: () => false
  },
  isOnEdit: {
    type: Boolean,
    required: true
  },
  childrenOnDrag: {
    type: Boolean,
    required: false,
    default: () => false
  },
  isOnDrag: {
    type: Boolean,
    required: true
  },
  isEmpty: {
    type: Boolean,
    required: false,
    default: () => false
  },
  isOverlaid: {
    type: Boolean,
    required: false,
    default: () => false
  },
  information: {
    type: Array,
    required: false,
    default: () => []
  },
  parentHover: {
    type: Boolean,
    required: false,
    default: () => false
  },
  parentOnEdit: {
    type: Boolean,
    required: false,
    default: () => false
  },
  childrenHover: {
    required: false,
    default: () => ({ value: false, tag: null })
  },
  transverseHover: {
    type: Boolean,
    required: false,
    default: () => false
  },
  availableActions: {
    type: Array,
    required: true
  },
  isSavedBlock: {
    type: Boolean,
    default: false
  },
  blockName: {
    type: String,
    default: ''
  }
}
// Vue@Properties

// Vue@data
const data = function () {
  return {
    showPopover: {},
    states: _cloneDeep(states)
  }
}
// Vue@data

// Vue@subComponents
const components = {
  MdiNoDesktop,
  MdiNoMobile,
  MdiDelete,
  MdiEdit,
  MdiMove,
  MdiCopy,
  MdiLinkOff,
  MdiUpdate,
  MdiPencilBox
}
// Vue@subComponents

const computed = {

  // Func@cssVar
  /**
   * Get css variable from js to css
   */
  cssVar () {
    if (this.isSavedBlock) {
      return {
        '--bd-color': selectorSavedBlockBorder,
        '--bg-color': selectorSavedBlockHeader
      }
    } else {
      switch (this.tag) {
        case 'mj-section': {
          return this.isNestedSection
            ? {
                '--bd-color': selectorNestedSectionBorder,
                '--bg-color': selectorNestedSectionHeader
              }
            : {
                '--bd-color': selectorSectionBorder,
                '--bg-color': selectorSectionHeader
              }
        }
        case 'mj-column': {
          return this.isNestedElement
            ? {
                '--bd-color': selectorColumnBorder,
                '--bg-color': selectorColumnHeader,
                '--pbd-color': selectorNestedSectionBorder,
                '--cbg-color': selectorContentHeader
              }
            : {
                '--bd-color': selectorColumnBorder,
                '--bg-color': selectorColumnHeader,
                '--pbd-color': selectorSectionBorder,
                '--cbg-color': _get(this.childrenHover, 'tag') === 'mj-section' ? selectorNestedSectionBorder : selectorContentHeader
              }
        }
        default: {
          return {
            '--bd-color': selectorContentBorder,
            '--bg-color': selectorContentHeader
          }
        }
      }
    }
  },
  // Func@cssVar

  selectorDisabled () {
    return (this.isNestedElement && !this.isOnEdit) || (this.isNestedElement && this.isOnEdit)
  },

  // Func@isContent
  /**
   * Check if it's content type
   * @return {Boolean}
   */
  isContent () {
    const type = this.tag.replace('mj-', '')
    return type !== 'section' && type !== 'column'
  },
  // Func@isContent

  // Func@isStandardMode
  /**
   * Check if it's standard mode
   * @return {Boolean}
   */
  isStandardMode () {
    return this.mode === 'standard'
  },
  // Func@isStandardMode

  // Func@isCheckboxMode
  /**
   * Check if it's standard mode
   * @return {Boolean} [description]
   */
  isCheckboxMode () {
    return this.mode === 'checkbox' && this.tag === 'mj-section'
  },
  // Func@isCheckboxMode

  // Func@selectorClass
  /**
   * Selector class identifier
   * @return {String}
   */
  selectorClass () {
    let classes = `mode-${this.mode}`
    const type = this.tag.replace('mj-', '')

    if (this.hideNoContent) classes += ' slim'
    if (this.states.showContentLabel) classes += ' show-content-label'
    if (this.tag) classes += ` ${this.isContent ? 'content' : type}`
    if (this.isEmpty) classes += ' empty'
    if (this.isChecked) classes += ' checked'
    if (this.screenModeFifty) classes += ' screen-fifty-percent'
    if (this.childrenHover.value) classes += ' children-hover'
    if (this.parentHover) classes += ' parent-hover'
    if (this.transverseHover) classes += ' transverse-hover'
    if (this.dragActive) classes += ' drag-active'
    if (this.isOnDrag) classes += ' on-drag'
    if (this.childrenOnDrag) classes += ' children-on-drag'
    if (this.editActive) classes += ' edit-active'
    if (this.isOnEdit) classes += ' on-edit'
    if (this.parentOnEdit) classes += ' parent-on-edit'
    if (this.isNestedSection || this.isNestedElement) classes += ' nested'
    if (this.isOverlaid) classes += ' overlaid'
    if (
      (this.isContent || this.isNestedSection) &&
      this.availableActions.includes('edit') &&
      !this.screenModeFifty
    ) classes += ' editable'
    if (this.isSavedBlock) {
      classes += ' saved-block'
    }
    if (this.showPopover.unlink || this.showPopover.update) classes += ' parent-hover children-hover'
    return classes
  },
  // Func@selectorClass

  // Func@actionsList
  /**
   * List of filtered actions
   * @return {Array} (List of actions)
   */
  actionsList () {
    return this.states.actions
      .filter(filter => this.availableActions.includes(filter.type))
  },
  // Func@actionsList

  // Func@informationList
  /**
   * List of filtered infommation
   * @return {Array} (List of information)
   */
  informationList () {
    return this.states.information
      .filter(filter => this.information.includes(filter.type))
  }
  // Func@informationList
}

// Methods
const methods = {

  // Func@onActionClick
  /**
   * Action event emitter
   * @param  {String} type (triggered action name)
   */
  onActionClick ({ type, onlyContent = null }) {
    this.showPopover = {}
    if ((onlyContent && !(this.isContent || this.isNestedSection)) || this.screenModeFifty) return
    // Remove hover on element delete
    if (type === 'delete') this.$emit('hover', false)

    if (type === 'unlink' || type === 'update')  {
      this.showPopover[type] = true
    } else {
      this.$emit('action', type)
    }
  },
  cancelActionClick () {
    this.showPopover = {}
  },
  confirmActionClick({ type }) {
    this.$emit('action', type)
    this.showPopover = {}
  },
  // Func@onActionClick

  // Func@checkSelectorSize
  /**
   * Check if actions selector container is
   * smallest than action menu
   */
  checkSelectorSize () {
    const actionButtonWitdh = 27 // 27px for each button
    const actionNbr = this.actionsList.length
    const selector = this.$refs.selector
    const selectorWidth = selector.offsetWidth

    this.states.isSmallSelector = selectorWidth < actionButtonWitdh * actionNbr
  },
  // Func@checkSelectorSize

  // Func@onHover
  /**
   * Emit action to propagate this one at any level
   * @param {Boolean} val
   */
  onHover (val) {
    this.checkSelectorSize()
    this.$emit('overflow', !val)
    if (!this.dragActive) {
      this.$emit('hover', val)
    }
  }
  // Func@onHover
}

const watch = {
  dragActive: {
    immediate: true,
    async handler () {
      this.showPopover = {}
    }
  },
}

// Vue component syntax
export default {
  name,
  data,
  props,
  methods,
  computed,
  components,
  watch
}
