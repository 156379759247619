// Config files
import userConfig from '@/assets/config/user/user.conf'
import textDefaultStyle from '@/assets/config/default-style/text.conf'

// Services
import { mjmlJson, metaog, mailLanguage, mailPreview, mailTitle } from '@/services/states/states.service'
import { getDefaultStyles, setDefaultStyles } from '@/services/dnd-style-engine/dnd-style-engine.service'
import checkRetroCompatibility from '@/services/dnd-compatibility/contents-compatibility.service'

// Css variable
import { colorCarouselBg } from '@/assets/styles/variables.scss'

import {
  get as _get,
  uniqueId as _uniqueId,
  cloneDeep as _cloneDeep,
  isArray as _isArray
} from 'lodash-es'

// Object@templateHeader
// Header to apply for MJML templates
const mjmlHeader = {
  tagName: 'mj-head',
  attributes: {},
  children: [
    {
      id: 'styleinline',
      tagName: 'mj-style',
      inline: 'inline',
      content: `
        .simple-carousel .mj-carousel-previous-icons,
        .simple-carousel .mj-carousel-next-icons {
          min-width: 44px;
        }
        .simple-carousel .mj-carousel-previous-icons img,
        .simple-carousel .mj-carousel-next-icons img {
          margin: auto;
          cursor: pointer;
        }
        .simple-carousel .mj-carousel-thumbnail img {
          display: none !important;
        }
        .simple-carousel .mj-carousel-thumbnail {
          cursor: default;
          border-radius: 25px;
          margin: 5px 5px 0 0;
          background-color: ${colorCarouselBg};
        }
      `
    },
    {
      id: 'style',
      tagName: 'mj-style',
      content: `
      .mj-text .dnd,
      .dnd-button .dnd,
      .mj-button-v2, .mj-button { margin: 0; }
      .dnd-button .dnd { margin: 0; }
    
      .is-rtl { text-align: right; }
      .show-on-mobile{
        display:none;
      }
      .mj-text .dnd {
        display: inline-block;
        width: 100%;
      }
      p {
        margin: 0px !important;
      }

      @media only screen and (max-width:480px) {
        .show-on-desktop,
        .show-on-desktop-outlook {
          display:none !important;
        }
        .show-on-mobile{
          width: 100% !important;
          display: table !important;
          max-height: none !important;
          overflow: visible !important;
        }
      }

      .mj-text a {
        color: #0000EE;
        text-decoration: none;
      }
      `
    },
    {
      id: 'fix',
      tagName: 'mj-raw',
      content: `
      <!--[if gt mso 15]>
      <style type="text/css" media="all">
        /* Outlook 2016 Height Fix */
        table, tr, td {border-collapse: collapse;}
        tr { font-size:0px; line-height:0px; border-collapse: collapse; }
      </style>
      <![endif]-->
      <!--[if gte mso 9]>
        <style>
        li {
          text-indent:-1em;
        }
        </style>
      <![endif]-->
      `
    },
    {
      id: 'metaog',
      tagName: 'mj-raw',
      content: ''
    },
    {
      id: 'preheader',
      tagName: 'mj-preview',
      content: ''
    },
    {
      id: 'title',
      tagName: 'mj-title',
      content: ''
    }
  ]
}
// Object@templateHeader

// Func@applyDefaultAttributes
/**
 * Apply default attributes when they are missing
 * @param  {String} type       (Componant type)
 * @param  {Object} attributes (template part attributes)
 */
async function _applyDefaultAttributes (mjComponent) {
  const tagName = mjComponent.tagName
  const resource = `${tagName}.conf`
  try {
    const conf = await import(/* webpackMode: "eager" */'@/assets/config/mjml-components/' + resource + '.json')
    const defaultAttributes = _cloneDeep(conf.attributes.default)
    const updatedAttributes = Object.assign(defaultAttributes, mjComponent.attributes)
    mjComponent.attributes = updatedAttributes

    if (tagName === 'mj-column') {
      updatedAttributes.activeStateIndex = 0
      if (!updatedAttributes.states) updatedAttributes.states = [{ default_operator: 'or', conditions: {}, operations: [] }]
    }

    if (tagName === 'mj-section') {
      mjComponent.id = _uniqueId(`${tagName}_`)
    }
  } catch (err) {
    console.error(err)
  }
}
// Func@applyDefaultAttributes

// Func@mjmlResponsiveTranlation
/**
 * Regroup row columns to disable responsive following https://mjml.io/documentation/#mjml-group
 * @param  {Object} row (Row DNDMJML object)
 */
function _mjmlResponsiveTranlation (row) {
  const children = row.children
  if (!row.attributes.responsive) {
    row.children = [
      {
        tagName: 'mj-group',
        attributes: {},
        children: children
      }
    ]
  }

  return {
    row,
    children
  }
}
// Func@mjmlResponsiveTranlation

// Func@dndResponsiveTranlation
/**
 * Ungroup columns and translate responsive disabled state https://mjml.io/documentation/#mjml-group
 * for DND
 * @param  {Object} row (Row DNDMJML object)
 */
function _dndResponsiveTranlation (row) {
  if (row.children.find(child => child.tagName === 'mj-group')) {
    row.children.splice(0, row.children.length, ...row.children[0].children)
    row.attributes.responsive = false
  }
  return row
}
// Func@dndResponsiveTranlation

// Func@cleanAttributes
/**
 * Check attributes and clean not supported empty  attribute
 * @param  {Object} attributes (template part attributes)
 */
function _cleanAttributes (attributes) {
  const notMjmlAttr = [
    'activeStateIndex',
    'responsive'
  ]
  const valuesNotUsed = [
    { attr: 'border', val: '0px' },
    { attr: 'border-top', val: '0px' },
    { attr: 'border-left', val: '0px' },
    { attr: 'border-right', val: '0px' },
    { attr: 'border-bottom', val: '0px' }
  ]
  const emptynotSupported = [
    'width',
    'height',
    'line-height',
    'background-url',
    'background-color',
    'background-size',
    'container-background-color'
  ]
  for (const key in attributes) {
    const hasExistingValue = Boolean(attributes[key])
    const notUsedKey = valuesNotUsed.find(notUsed => notUsed.attr === key)

    if (
      (emptynotSupported.includes(key) && !hasExistingValue) ||
      (notUsedKey && attributes[key].includes && attributes[key].includes(notUsedKey.val) && attributes[key].slice(0, 1) === '0') ||
      notMjmlAttr.includes(key)
    ) {
      delete attributes[key]
    }
  }
}
// Func@cleanAttributes


// Func@convertMjButtonV2
/**
 * Check mj-button and rename to mj-button-v2
 * @param  {Object}  (content)
 */

function _convertMjButtonV2 (content) {
  content.tagName = 'mj-button-v2'
}


// Func@convertMjSectionV2
/**
 * Check mj-button and rename to mj-section
 * @param  {Object}  (content)
 */

function _convertMjSectionV2 (content) {
  content.tagName = 'mj-section'
}


function _setParentBackgroundImage (content, value) {
  if (content.tagName.includes('mj-button')) {
    content.attributes['parentBackgroundImage'] = `${value}`
  }
}




// Func@dynamicRowParser
/**
 * Parse mj-column object depending on source and destination
 * @param  {Boolean} options.toDND (json for DND manipulation)
 * @param  {Object}  options.row   (MJML row definition)
 */
function _dynamicRowParser ({ toDND = false, row }) {
  const cssClass = _get(row, 'attributes.css-class', '').split(' ')
  const isMobileOnly = cssClass.includes('show-on-mobile')
  if (!isMobileOnly) return

  if (toDND) {
    row.tagName = 'mj-section'
    return
  }

  // Switch to mobile row
  row.tagName = 'mj-section-mobile'
}
// Func@dynamicRowParser

// Func@dynamicColumnParser
/**
 * Parse mj-column object depending on source and destination
 * @param  {Boolean} options.toDND  (json for DND manipulation)
 * @param  {Object}  options.column (MJML column definition)
 */
function _dynamicColumnParser ({ toDND = false, column, bodyWidth, columnCount }) {
  const states = column.attributes.states
  const hasPerso =
    (states !== undefined && states.length > 1) ||
    (states !== undefined && states[0].operations.length > 0)
  if (toDND) {
    // Update to DND format
    if (!hasPerso) {
      column.children = [column.children]
      column.id = _uniqueId(`${column.tagName}_`)
    } else {
      column.tagName = 'mj-column'
      column.children = column.children.map(state => state.children)
      column.id = _uniqueId(`${column.tagName}_`)
    }
    // Refresh all content ids
    column.children.map(state => {
      if (_isArray(state)) {
        state.map(content => {
          content.id = _uniqueId(`${content.tagName}_`)
          return content
        })
      } else {
        state.id = _uniqueId(`${state.tagName}_`)
        return state
      }
    })
    return
  }

  if (!hasPerso) {
    delete column.attributes.states
    column.children = column.children[0]
    // fix for outlook width
    column.children.forEach(child => {
      // nested
      if (child.tagName === 'mj-section') {
        child.children.forEach(col => {
          col.children.forEach(elem => {
            if (elem.tagName === 'mj-button-v2') {
              const sectionWidth = column.attributes.width ? parseInt(column.attributes.width.substring(0, column.attributes.width.length - 1)) / 100 : 1
              const colWidth = col.attributes.width ? parseInt(col.attributes.width.substring(0, col.attributes.width.length - 1)) / 100 : parseFloat(100 / child.children.length) / 100
              elem.attributes.parentWidth = sectionWidth * colWidth * 100 + '%'
              elem.attributes.containerWidth = bodyWidth
            }
          })
        })
      } else {
        if (child.tagName === 'mj-button-v2') {
          child.attributes.parentWidth = column.attributes.width ? column.attributes.width : parseFloat(100 / columnCount) + '%'
          child.attributes.containerWidth = bodyWidth
        }
      }
    })
    return
  }
  // Switch to conditional column
  column.tagName = 'mj-column-perso'
  // Wrap each state in MJML column definition (tricks)
  column.children = column.children
    .map(stateContents => {
      return {
        tagName: 'mj-column',
        attributes: {},
        children: [...stateContents]
      }
    })
}
// Func@dynamicColumnParser

// Func@_buildCssHeader
/**
 * Update css headers
 * @return {String} (Css rules)
 */
function _buildCssHeader () {
  const style = mjmlHeader.children.find(child => child.id === 'style')
  const styleConf = [
    { key: '.mj-text a', value: getDefaultStyles().links() }
  ]

  styleConf.forEach(css => {
    const regExp = new RegExp(`${css.key} {([^}]*)}`) // nosemgrep
    const newStyles = style.content.replace(regExp, `${css.key} { ${css.value} }`)
    style.content = newStyles
  })

  return mjmlHeader
}
// Func@_buildCssHeader

// Func@_setDndCssConfig
/**
 * Convert css from header to DND conf
 * @param {Object} headers (headers)
 */
function _setDndCssConfig (headers) {
  const style = _get(headers.children, '1')
  const styleConf = [
    { key: '.mj-text a', type: 'links' }
  ]

  if (!style) return

  styleConf.forEach(css => {
    const regExp = new RegExp(`${css.key} {([^}]*)}`) // nosemgrep
    const result = style.content.match(regExp)
    const loadedStyle = _get(result, '1')
    if (loadedStyle) setDefaultStyles()[css.type](loadedStyle)
  })
}
// Func@_setDndCssConfig

// Func@parseHeader
/**
 * Parse Header input/output format
 * @param  {Object} options.formOg    (Meta og)
 * @param  {String} options.preHeader (Preheader)
 * @return {Methods} (setMetaOg, getMetaOg, setPreHeader, getPreHeader)
 */
function parseHeader ({ formOg, headers } = {}) {
  return {
    // Func@parseHeader

    // Func@setMetaOg
    /**
     * Prepare mjml file to have meta og
     * @return {Object} (MJML json)
     */
    setMetaOg: () => {
      // Write in MJML header
      const metaOg = mjmlHeader.children.find(child => child.id === 'metaog')
      metaOg.content = `<meta property="og:title" content="${formOg.title || ''}"><meta property="og:site_name" content="${formOg.sitename || ''}"><meta property="og:description" content="${formOg.description || ''}"><meta property="og:image" content="${formOg.imagepath || ''}">`

      // Write in DND state service
      metaog().set(formOg)

      return metaOg
    },
    getMetaOg: () => {
      const parseTable = {
        'og:title': 'title',
        'og:image': 'imagepath',
        'og:site_name': 'sitename',
        'og:description': 'description'
      }
      const metaOgHeader = mjmlHeader.children.find(child => child.id === 'metaog')
      const html = document.createElement('html')
      html.innerHTML = metaOgHeader.content
      const metas = html.getElementsByTagName('meta')

      const ogProps = {}
      for (let i = 0; i < metas.length; i++) {
        const prop = metas[i].getAttribute('property')
        ogProps[parseTable[prop]] = metas[i].getAttribute('content')
      }
      metaog().set(ogProps)
    },
    /**
     * Prepare file to have preheader
     */
    setPreHeader: preHeader => {
      const mjPreview = mjmlHeader.children.find(child => child.id === 'preheader')
      mjPreview.content = preHeader || ''
    },
    getPreHeader: () => {
      const preHeader = headers.children.find(child => child.id === 'preheader') || mjmlHeader.children.find(child => child.id === 'preheader')
      mailPreview({ fromBackup: true }).set(preHeader.content)
    },
    setCustomFont: () => {
      const fonts = [
        ...textDefaultStyle.fonts.imported.map(f => {
          const isProd = process.env.NODE_ENV === 'production'
          f.font_url = isProd ? f.font_url.replaceAll('{{env}}', 'prod') : f.font_url.replaceAll('{{env}}', 'dev') 
          f.fontface_url = isProd ? f.fontface_url.replaceAll('{{env}}', 'prod') : f.fontface_url.replaceAll('{{env}}', 'dev') 
          return f
        }), 
        ...userConfig.fonts
      ]
      mjmlHeader.children = mjmlHeader.children.filter(c => c.tagName !== 'mj-font')
      fonts.forEach(font => {
        mjmlHeader.children.push({
          tagName: 'mj-font',
          attributes: {
            name: font.name,
            href: font.fontface_url
          }
        })
      })
    },
    setTitle: title => {
      const mjTitle = mjmlHeader.children.find(child => child.id === 'title')
      mjTitle.content = title || ''
    },
    getTitle: () => {
      const title = headers.children.find(child => child.id === 'title') || mjmlHeader.children.find(child => child.id === 'title')
      mailTitle({ fromBackup: true }).set(title.content)
    },
  }
}


// Func@parseTemplate
/**
 * Parse template input/output format
 * @param  {Object} options.template (template JSON)
 * @param  {Object} options.block    (block JSON)
 * @param  {Object} options.formOg    (meta og JSON)
 * @return {Methods} (toMjml, toDnd)
 */
function parseTemplate ({ template, block, formOg }) {
  let mjml, headers, body, rows

  if (template) {
    mjml = typeof template === 'string'
      ? JSON.parse(template)
      : _cloneDeep(template)
    headers = _get(mjml, 'children.1', {})
    body = _get(mjml, 'children.0', {})

    const templateCopy = _cloneDeep(mjml)

    if (templateCopy.children.find(child => child.tagName === 'mj-head') && templateCopy.children.find(child => child.tagName === 'mj-head').children.find(child => child.id === 'metaog') && !formOg) {
      mjmlHeader.children.find(child => child.id === 'metaog').content = templateCopy.children.find(child => child.tagName === 'mj-head').children.find(child => child.id === 'metaog').content
    }

    if (formOg) {
      mjml.children
        .push(parseHeader({ template, block, formOg }).setMetaOg())
    }
    const title = mailTitle().get()
    parseHeader().setTitle(title)

    const preHeader = mailPreview().get()
    parseHeader().setPreHeader(preHeader)
    parseHeader().setCustomFont()

    if (mjml.attributes && mjml.attributes.lang && mjml.attributes.lang.length) {
      mailLanguage().set(mjml.attributes.lang)
    }
    rows = body.children
  } else {
    mjml = rows = typeof block === 'string'
      ? JSON.parse(block)
      : _cloneDeep(block)
  }

  return {
  // Func@parseTemplate
    // Func@toMjml
    /**
     * Prepare mjml file to be fully MJML compliant
     * @return {Object} (MJML json)
     */
    // Func@parseTemplate
    toMjml: ({ toString }) => {
    // Func@parseTemplate
      if (template) {
        mjml.children.length = 0
        mjml.children = [body, _buildCssHeader()]
      }
      if (body) _cleanAttributes(body.attributes)
      // Parse row && and cleaning attr
      rows.forEach(row => {
        _dynamicRowParser({ row })
        const updatedSection = _mjmlResponsiveTranlation(row)
        _cleanAttributes(updatedSection.row.attributes)
         // Parse column && and cleaning attr
        updatedSection.children.forEach(column => {
          _cleanAttributes(column.attributes)
          // Navigate to contents (deep in column states/perso)
          column.children.forEach(stateContents => {
            if (Array.isArray(stateContents)) {
              stateContents.forEach(content => {
                if (content.tagName === 'mj-section') {
                  _convertMjSectionV2(content)
                }
                if (content.tagName === 'mj-section') {
                  // Parse nested rows && and cleaning attr
                  const nestedRow = content
                  _dynamicRowParser({ row: nestedRow })
                  const updatedNestedSection = _mjmlResponsiveTranlation(nestedRow)
                  _cleanAttributes(updatedNestedSection.row.attributes)
                  // Parse nested columns && and cleaning attr
                  updatedNestedSection.children.forEach(nestedColumn => {
                    _cleanAttributes(nestedColumn.attributes)
                    // Navigate to nested contents (deep in column states/perso)
                    nestedColumn.children.forEach(stateNestedContents => {
                      if (Array.isArray(stateNestedContents)) {
                        stateNestedContents.forEach(nestedContent => {
                          // Parse contents && and cleaning attr
                          _cleanAttributes(nestedContent.attributes)
                          if (updatedSection.row.attributes['background-url'] || updatedNestedSection.row.attributes['background-url']) {
                            _setParentBackgroundImage(nestedContent, true)
                          } else {
                            _setParentBackgroundImage(nestedContent, false)
                          }
                          if (nestedContent.tagName === 'mj-button') {
                            _convertMjButtonV2(nestedContent)
                          }
                          nestedContent.children.forEach(deepNestedContent => {
                            _cleanAttributes(deepNestedContent.attributes)
                            deepNestedContent.children.forEach(subContent => {
                              _cleanAttributes(subContent.attributes)
                            })
                          })
                        })
                      } else {
                        // Parse contents && and cleaning attr
                        _cleanAttributes(stateNestedContents.attributes)
                        if (stateNestedContents.tagName === 'mj-button') {
                          _convertMjButtonV2(stateNestedContents)
                        }
                        if (stateNestedContents.children) {
                          stateNestedContents.children.forEach(deepNestedContent => {
                            _cleanAttributes(deepNestedContent.attributes)
                            if (deepNestedContent.tagName === 'mj-button') {
                              _convertMjButtonV2(deepNestedContent)
                            }
                            deepNestedContent.children.forEach(subContent => {
                              _cleanAttributes(subContent.attributes)
                            })
                          })
                        }
                      }
                    })
                    _dynamicColumnParser({ column: nestedColumn, bodyWidth: body.attributes.width, columnCount: updatedNestedSection.children.length })
                  })
                } else {
                  // Convert mj-button to mj-button-v2
                  if (updatedSection.row.attributes['background-url']) {
                    _setParentBackgroundImage(content, true)
                  } else {
                    _setParentBackgroundImage(content, false)
                  }
                  if (content.tagName === 'mj-button') {
                    _convertMjButtonV2(content)
                  }
                  // Parse contents && and cleaning attr
                  _cleanAttributes(content.attributes)
                  if (stateContents.children) {
                    content.children.forEach(deepContent => {
                      _cleanAttributes(deepContent.attributes)
                      deepContent.children.forEach(subContent => {
                        _cleanAttributes(subContent.attributes)
                      })
                    })
                  }
                }
              })
            }
            if (column.tagName !== 'mj-column-perso') {
              _dynamicColumnParser({ column, bodyWidth: body.attributes.width, columnCount: updatedSection.children.length })
            }
          })
        })
      })
      // Save MJML JSON
      mjmlJson().save(mjml)
      return toString ? JSON.stringify(mjml) : mjml
    },
    // Func@parseTemplate
    // Func@toMjml

    // Func@toDnd
    /**
     * Prepare mjml file to be fully DND compliant
     * @return {Object} (MJML json)
     */
    // Func@parseTemplate
    toDnd: async () => {
      parseHeader().getMetaOg()

      // Temporary fix
      // https://gitlab.1000mercis.com/fs_qa/html_editor-qa/issues/259
      // Detect if it's already a DNDMJML file
      if (_isArray(_get(rows, '0.children.0.children.0'))) return mjml

      if (template) {
        _setDndCssConfig(headers)
        parseHeader({ headers }).getPreHeader()
        parseHeader({ headers }).getTitle()
      }
      if (mjml.attributes && mjml.attributes.lang) {
        mailLanguage({ force: true }).set(mjml.attributes.lang)
      }
      if (body) await _applyDefaultAttributes(body)
      // Parse row && apply default attr
      await Promise.all(rows.map(async row => {
        _dynamicRowParser({ toDND: true, row })
        const translatedRow = _dndResponsiveTranlation(row)
        _convertMjSectionV2(translatedRow)
        await _applyDefaultAttributes(translatedRow)

        // Parse columns and apply default attr
        await Promise.all(translatedRow.children.map(async column => {
          _dynamicColumnParser({ toDND: true, column })
          await _applyDefaultAttributes(column)
          // Navigate to contents (deep in column states/perso)
          await Promise.all(column.children.map(async stateContents => {
            await Promise.all(stateContents.map(async content => {
              const checkedContent = checkRetroCompatibility(content)
              // Parse nested rows && apply default attr
              if (checkedContent.tagName === 'mj-section') {
                const nestedRow = checkedContent
                _convertMjSectionV2(nestedRow)
                _dynamicRowParser({ toDND: true, row: nestedRow })
                const translatedNestedRow = _dndResponsiveTranlation(nestedRow)
                await _applyDefaultAttributes(translatedNestedRow)

                // Parse nested columns and apply default attr
                await Promise.all(translatedNestedRow.children.map(async nestedColumn => {
                  _dynamicColumnParser({ toDND: true, column: nestedColumn })
                  await _applyDefaultAttributes(nestedColumn)

                  // Navigate to nested contents (deep in column states/perso)
                  await Promise.all(nestedColumn.children.map(async stateNestedContents => {
                    if (_isArray(stateNestedContents)) {
                      await Promise.all(stateNestedContents.map(async nestedContent => {
                        const checkedNestedContent = checkRetroCompatibility(nestedContent)
                        if (checkedNestedContent.tagName === 'mj-button') {
                          _convertMjButtonV2(checkedNestedContent)
                        }
                        await _applyDefaultAttributes(checkedNestedContent)
                      }))
                    } else {
                      const checkedNestedContent = checkRetroCompatibility(stateNestedContents)
                      if (checkedNestedContent.tagName === 'mj-button') {
                        _convertMjButtonV2(checkedNestedContent)
                      }
                      await _applyDefaultAttributes(checkedNestedContent)
                    }
                  }))
                }))

              // Apply default attr to contents
              } else {
                // Convert mj-button to mj-button-v2
                if (content.tagName === 'mj-button') {
                  _convertMjButtonV2(content)
                }
                await _applyDefaultAttributes(checkedContent)
              }
            }))
          }))
        }))
      }))

      // TODO Parse BLOCKS VERSIONS

      return mjml
    // Func@parseTemplate
    }
    // Func@parseTemplate
    // Func@toDnd
  // Func@parseTemplate
  }
}
// Func@parseTemplate


function generateSavedBlockStructure(json) {
  return {
    "tagName": "mj-section",
    "attributes": {
        "css-class": "",
        "border-radius": "0",
        "padding": "0 0 0 0",
        "background-repeat": "no-repeat",
        "direction": "ltr"
    },
    "children": [
        {
            "tagName": "mj-column",
            "attributes": {
                "padding": "0 0 0 0",
                "vertical-align": "top"
            },
            "children": [
                {
                    "tagName": "mj-section",
                    "attributes": {
                        "css-class": "",
                        "border-radius": "0",
                        "padding": "0 0 0 0",
                        "background-repeat": "no-repeat",
                        "direction": "ltr"
                    },
                    "children": [
                        {
                            "tagName": "mj-column",
                            "attributes": {
                                "padding": "0 0 0 0",
                                "vertical-align": "top"
                            },
                            "children": json,
                            "id": _uniqueId('mj-column_')
                        }
                    ],
                    "id": _uniqueId('mj-section_')
                }
            ],
            "id": _uniqueId('mj-column_')
        }
    ],
    "id": _uniqueId('mj-section_')
  }
}

export {
  parseTemplate,
  parseHeader,
  _dndResponsiveTranlation,
  _mjmlResponsiveTranlation,
  generateSavedBlockStructure
}
