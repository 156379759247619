import globalstates from './states.config.json'
// Default style conf
import textDefaultStyle from '@/assets/config/default-style/text.conf'
import componantsDefaultStyle from '@/assets/config/default-style/componants.conf'

import mjText from '@/assets/config/mjml-components/mj-text.conf.json'

// Services
import History from '@/services/dnd-history/dnd-history.service'
import { eventEmit } from '@/services/utils/utils.service'

// Library
import { get as _get, uniqueId as _uniqueId } from 'lodash-es'

// Object@editpanel
const o = {
  mjml: globalstates.mjml,
  user: globalstates.user,
  workspace: globalstates.workspace,
  preheader: globalstates.preheader,
  title: globalstates.title,
  lang: globalstates.lang,
  metaog: globalstates.metaog,
  leftPanel: globalstates.leftPanel,
  editPanel: globalstates.leftPanel.editPanel,
  deepEditPanel: globalstates.leftPanel.deepEditPanel,
  blockBuilderPanel: globalstates.leftPanel.blockBuilderPanel,
  openGraphPanel: globalstates.leftPanel.openGraphPanel,
  htmlVersion: globalstates.htmlVersion,
  htmlsBlocksVersions: globalstates.workspace.htmlsBlocksVersions,
  outdatedBlocks: globalstates.outdatedBlocks,
  conf: {
    textDefaultStyle,
    componantsDefaultStyle
  }
}
o.workspace.history = new History({ maxSize: 10 })

// Save basic default style
o.user.history = new History({ maxSize: 10 })
o.user.history.init(buildDefaultStylesObject())

// Object@editpanel

// Func@openEditPanel
/**
 * Open edit panel from anywhere
 * @param  {Object}  options.parent   (Parent component)
 * @param  {Array}   options.contents (list of MJML contents)
 * @param  {Number}  options.index    (Context position)
 * @param  {Boolean} options.isModal  (Open modal version of the edit panel, deepEditPanel)
 */
function openEditPanel ({ parent = null, contents = [], index = null, isModal = false, modifiers = {} }) {
  if (!contents.length || index === null || (!isModal && o.editPanel.lockState)) return
  const panelType = isModal ? 'deepEditPanel' : 'editPanel'

  const tagName = contents[index].tagName
  const isStructure = (tagName === 'mj-section' || tagName === 'mj-column')
  o[panelType].id = contents[index].id
  o[panelType].type = isStructure ? 'structure' : 'content'
  o[panelType].index = index
  o[panelType].active = true
  o[panelType].parent = parent
  o[panelType].tagName = tagName
  o[panelType].contents = contents
  o[panelType].modifiers = modifiers
}
// Func@openEditPanel

// Func@closeEditPanel
/**
 * Close edit panel from anywhere
 */
function closeEditPanel ({ isModal = false } = {}) {
  if (!isModal && o.editPanel.lockState) return
  const panelType = isModal ? 'deepEditPanel' : 'editPanel'

  o[panelType].id = null
  o[panelType].type = null
  o[panelType].index = null
  o[panelType].contents = []
  o[panelType].active = false
  o[panelType].tagName = null
}
// Func@closeEditPanel

// Func@lockEditPanel
/**
 * Lock edit panel (open and close disabled)
 * @param  {Bolean} val (lock value)
 * @param {String} type (type of alert)
 */
function lockEditPanel (val, type) {
  o.editPanel.lockState = val
  o.deepEditPanel.lockState = val

  if (!val) {
    o.editPanel.errorType = null
    o.deepEditPanel.lockState = null
    return
  }

  o.editPanel.errorType = type
  o.deepEditPanel.errorType = type
}
// Func@lockEditPanel

// Func@alertEditPanel
/**
 * Alert edit panel
 * @param  {Boolean} val (lock value)
 * @param {String} type (type of alert)
 */
function alertEditPanel (val, type) {
  if (!val) {
    o.editPanel.errorType = null
    return
  }

  o.editPanel.errorType = type
}
// Func@alertEditPanel

// BLOCK BUILDER PANEL //
// Func@openBlockBuilderPanel
/**
 * Open block builder panel from anywhere
 * @param  {Array}  options.contents (list of MJML contents)
 * @param  {Number} options.index    (Context position)
 */
function openBlockBuilderPanel () {
  o.blockBuilderPanel.active = true
}
// Func@openBlockBuilderPanel

// Func@closeBlockBuilderPanel
/**
 * Close block builder panel from anywhere
 */
function closeBlockBuilderPanel () {
  if (o.blockBuilderPanel.lockState) return
  o.blockBuilderPanel.active = false
}
// Func@closeBlockBuilderPanel

// Func@lockBlockBuilderPanel
/**
 * Lock edit panel (open and close disabled)
 * @param  {Bolean} val (lock value)
 */
function lockBlockBuilderPanel (val) {
  o.blockBuilderPanel.lockState = val
}
// Func@lockBlockBuilderPanel

// Func@setActiveLeftPane
/**
 * Set current panel mode (General/structure/contents)
 * @param {String} val (panel to activate)
 */
function setActiveLeftPane (val) {
  o.leftPanel.active = val
}
// Func@setActiveLeftPane

// Func@expandLeftPanel
/**
 * Expand left panel
 */
function expandLeftPanel () {
  o.leftPanel.fiftyPercentWidth = !o.leftPanel.fiftyPercentWidth
}
// Func@expandLeftPanel

// Func@setOverview
function setOverview (html) {
  o.workspace.overview = html
}
// Func@setOverview

// Func@shiftShortcuts
function shiftShortcuts () {
  return {
    enable: () => {
      o.workspace.shortcuts.disableShiftBased = false
    },
    disable: () => {
      o.workspace.shortcuts.disableShiftBased = true
    }
  }
}
// Func@shiftShortcuts

// Func@fileManager
function fileManager () {
  return {
    get () {
      return o.workspace.fileManager
    },
    set (val) {
      o.workspace.fileManager = val
    }
  }
}
// Func@fileManager

// Func@qrcodeManager
function qrcodeManager () {
  return {
    get () {
      return o.workspace.qrcodeManager
    },
    set (val) {
      o.workspace.qrcodeManager = val
    }
  }
}
// Func@qrcodeManager

// Func@resourceWriteAuthorized
function setResourceWriteAuthorized (val) {
  o.workspace.resourceWriteAuthorized = val
}
// Func@resourceWriteAuthorized

// Func@userConfigLoaded
function userConfigLoaded () {
  return {
    get () {
      return o.user.configLoaded
    },
    set (val) {
      o.user.configLoaded = val
    }
  }
}
// Func@userConfigLoaded

// Func@devmode
function devmode () {
  return {
    get () {
      return o.workspace.devmode
    },
    set (val) {
      o.workspace.devmode = val
    }
  }
}
// Func@devmode

// Func@devmode
function mjmlOptimized () {
  return {
    get () {
      return o.mjml.optimized
    },
    set (val) {
      o.mjml.optimized = val
    }
  }
}
// Func@devmode

// Func@getBgColorsLayers
/**
 * Get layers of BG colors from an id (section/column/content)
 * @param  {String} id (target id)
 *
 * @return {Array}    (Layers)
 */
function _getBgColorsLayers (id) {
  let bodyValue, sectionValue  = {}, colValue = {}, contentValue = {}, 
  deepContentValue = {}, subContentValue = {}, layers= []
  const targetType = id.split('_').shift()
  const body = _get(o.workspace.mjmlJson, 'children', []).find(el => el.tagName === 'mj-body')
  const rows = _get(body, 'children')

  if (!body || !rows) return []

  bodyValue = {
    layer: 'body',
    bgColor: body.attributes['background-color']
  }

  /* eslint no-labels: ["error", { "allowLoop": true }] */
  switch (targetType) {
    case 'section': {
      colValue = {}, contentValue = {}, deepContentValue = {}, 
      subContentValue = {}, layers= []
      const targetRow = body.children.find(row => row.id === id)
      if (targetRow.attributes['background-color']) {
        layers.unshift({
          layer: 'section',
          bgColor: targetRow.attributes['background-color']
        })
      }
      return layers
    }

    case 'column': {
      loop1: for (const row in rows) {
        colValue = {}, contentValue = {}, deepContentValue = {}, 
        subContentValue = {}, layers = []
        sectionValue = {
          layer: 'section',
          bgColor: rows[row].attributes['background-color']
        }

        const columns = rows[row].children
        for (const column in columns) {
          contentValue = {}, deepContentValue = {}, 
          subContentValue = {}
          colValue = {
            layer: 'column',
            bgColor: columns[column].attributes['background-color']
          }
          if (columns[column].id === id) break loop1
        }
      }

      if (_get(sectionValue, 'bgColor')) layers.unshift(sectionValue)
      if (_get(colValue, 'bgColor')) layers.unshift(colValue)

      return layers
    }

    default: {
      loop1: for (const row in rows) {
        colValue = {}, contentValue = {}, deepContentValue = {}, 
        subContentValue = {}, layers= []
        sectionValue = {
          layer: 'section',
          bgColor: rows[row].attributes['background-color']
        }

        const columns = rows[row].children
        for (const column in columns) {
          contentValue = {}, deepContentValue = {}, 
          subContentValue = {}
          colValue = {
            layer: 'column',
            bgColor: columns[column].attributes['background-color']
          }

          // Contents can be also deep rows
          const contents = columns[column].children
          for (const content in contents) {
            contentValue = { layer: 'content' }
            switch (contents[content].tagName) {
              case 'mj-text':
              case 'mj-accordion':
                contentValue.bgColor = contents[content].attributes['container-background-color']
                break
              default:
                contentValue.bgColor = contents[content].attributes['background-color']
            }
            if (contents[content].id === id) {
              break loop1
            }

            // Deep contents are deep rows columns
            const deepContents = contents[content].children
            for (const deepContent in deepContents) {
              deepContentValue = { layer: 'deepContent' }
              switch (deepContents[deepContent].tagName) {
                default:
                  deepContentValue.bgColor = deepContents[deepContent].attributes['background-color']
              }
              if (deepContents[deepContent].id === id) {
                break loop1
              }

              // Sub contents are deep rows contents
              const subContents = deepContents[deepContent].children
              for (const subContent in subContents) {
                subContentValue = { layer: 'subContent' }
                switch (subContents[subContent].tagName) {
                  case 'mj-text':
                  case 'mj-accordion':
                    subContentValue.bgColor = subContents[subContent].attributes['container-background-color']
                      break
                  default:
                    subContentValue.bgColor = subContents[subContent].attributes['background-color']
                }
                if (subContents[subContent].id === id) {
                  break loop1
                }
              }
            }
          }
        }
      }

      if (_get(bodyValue, 'bgColor')) layers.unshift(bodyValue)
      if (_get(sectionValue, 'bgColor')) layers.unshift(sectionValue)
      if (_get(colValue, 'bgColor')) layers.unshift(colValue)
      if (_get(contentValue, 'bgColor')) layers.unshift(contentValue)
      if (_get(deepContentValue, 'bgColor')) layers.unshift(deepContentValue)
      if (_get(subContentValue, 'bgColor')) layers.unshift(subContentValue)

      return layers
    }
  }
}
// Func@getBgColorsLayers

// Func@mjmlJson
/**
 * Get or Store (set) generated JSON
 * depending context we can get specifics
 * information from the saved template.
 *
 * @return {JSON} (MJML Saved file)
 */
function mjmlJson () {
  return {
    get ({ context, id } = {}) {
      if (!context && !id) return o.workspace.mjmlJson
      switch (context) {
        case 'bg-color-layers':
          return _getBgColorsLayers(id)
      }
    },
    save (val) {
      o.workspace.mjmlJson = val
    }
  }
}
// Func@mjmlJson

// Func@landingPageMode
function landingPageMode () {
  return {
    get () {
      return o.workspace.landingPageMode
    },
    set (val) {
      o.workspace.landingPageMode = val
    }
  }
}
// Func@landingPageMode

// Func@savedBlockMode
function savedBlockMode () {
  return {
    get () {
      return o.workspace.savedBlockMode
    },
    set (val) {
      o.workspace.savedBlockMode = val
    }
  }
}
// Func@savedBlockMode

// Func@readingDirection
function readingDirection () {
  return {
    get () {
      return o.workspace.defaultReadingDirection
    },
    set (val) {
      o.workspace.defaultReadingDirection = val
      mjText.attributes.default.align = val === 'rtl' ? 'right' : 'left'
    }
  }
}
// Func@readingDirection

// META OPEN GRAPH PANEL //
// Func@openMetaOpenGraphPanel
/**
 * Open block builder panel from anywhere
 * @param  {Array}  options.contents (list of MJML contents)
 * @param  {Number} options.index    (Context position)
 */
function openOpenGraphPanel () {
  o.openGraphPanel.active = true
  o.openGraphPanel.editing = true
}
// Func@openMetaOpenGraphPanel

// Func@closeOpenGraphPanel
/**
 * Close block builder panel from anywhere
 */
function closeOpenGraphPanel () {
  if (o.openGraphPanel.lockState) return
  o.openGraphPanel.active = false
  o.openGraphPanel.editing = false
}
// Func@closeOpenGraphPanel

// Func@metaOg
function metaog () {
  return {
    get () {
      return o.metaog
    },
    set (og) {
      o.metaog.title = og.title || ''
      o.metaog.sitename = og.sitename || ''
      o.metaog.imagepath = og.imagepath || ''
      o.metaog.description = og.description || ''
    }
  }
}
// Func@metaOg

// Func@gotMetaOg
function gotMetaOg () {
  return {
    get () {
      return o.openGraphPanel.gotMetaOg
    },
    set (value) {
      o.openGraphPanel.gotMetaOg = value
    }
  }
}
// Func@gotMetaOg

// Func@mailPreview
function mailPreview ({ fromBackup = false } = {}) {
  return {
    get () {
      if (fromBackup) return o.preheader.content
      return o.preheader.content && o.preheader.content.length ? `${o.preheader.content} ${o.preheader.hideContentFix.repeat(100)}` : null
    },
    set (value) {
      if (fromBackup) {
        o.preheader.content = value.split('&nbsp;&zwnj;')[0].trim()
        eventEmit('preheader', o.preheader.content)
        return
      }
      o.preheader.content = value
    }
  }
}
// Func@mailPreview


// Func@mailTitle
function mailTitle ({ fromBackup = false } = {}) {
  return {
    get () {
      if (fromBackup) return o.title.content
      return o.title.content || null
    },
    set (value) {
      o.title.content = value
      if (fromBackup) {
        eventEmit('title', o.title.content)
        return
      }
    }
  }
}
// Func@mailTitle

// Func@mailLanguage
function mailLanguage ({ force = false } = {}) {
  return {
    get () {
      if (force) return o.lang.content
      return o.lang.content || null
    },
    set (value) {
      o.lang.content = value
      if (force) {
        eventEmit('lang', o.lang.content)
        return
      }
    }
  }
}
// Func@mailLanguage

// Func@setEditingOpenGraphPanel
/**
 * edit open graph panel (open and close disabled)
 * @param  {Boolean} val (editing value)
 */
function setEditingOpenGraphPanel (val) {
  o.openGraphPanel.editing = val
}
// Func@setEditingOpenGraphPanel

// Func@lockOpenGraphPanel
/**
 * Lock edit panel (open and close disabled)
 * @param  {Bolean} val (lock value)
 */
function lockOpenGraphPanel (val) {
  o.openGraphPanel.lockState = val
}
// Func@lockOpenGraphPanel

// Func@workSpaceMode
function workSpaceMode () {
  return {
    get () {
      return o.workspace.mode
    },
    set (val) {
      o.workspace.mode = val
    }
  }
}
// Func@workSpaceMode

// Func@hideInvisibleElements
function hideInvisibleElements () {
  return {
    get () {
      return o.workspace.hideInvisibleElements
    },
    set (val) {
      o.workspace.hideInvisibleElements = val
    }
  }
}
// Func@hideInvisibleElements

// Func@setGabaritId
function setGabaritId () {
  return {
    get () {
      return o.workspace.gabaritId
    },
    set (val) {
      o.workspace.gabaritId = val
    }
  }
}
// Func@setGabaritId

// Func@currentGabaritId
function currentGabaritId () {
  return {
    get () {
      return o.workspace.currentGabaritId
    },
    set (val) {
      o.workspace.currentGabaritId = val
    }
  }
}
// Func@currentGabaritId

// Func@dnd
function dnd (level) {
  return {
    get (key) {
      return o.workspace.drag[key]
    },
    set (value) {
      o.workspace.drag = {
        active: value,
        level: value ? level : null
      }
    }
  }
}
// Func@dnd

// Func@dndState
/**
 * Set drag & drop state for all the workspace,
 * this func is triggered by all drag & drop container
 * whatever the source of the drag
 *
 * @param  {Boolean} options.isSource  (to identify if the the trigger is the source)
 * @param  {Object}  options.payload   (data when new element on drag)
 * @param  {Boolean} options.value     (is drag active ? or drag end ?)
 * @param  {Boolean} options.isContent (explicite source cause this.isContent unavailable)
 */
function dndState ({ componentId, value = false, context = {} }) {
  switch (true) {
    case componentId && componentId === 'mj-section#new':
      dnd('section-new').set(value)
      return value ? 'section-new' : null
    case componentId && componentId.includes('#new'):
      dnd('content-new').set(value)
      return value ? 'content-new' : null
    case context.isSection || (componentId && componentId === 'mj-section' && !context.isContent):
      dnd('section').set(value)
      return value ? 'section' : null
    case context.isColumn || (componentId && componentId === 'mj-column'):
      dnd('column').set(value)
      return value ? 'column' : null
    case context.isContent && componentId === 'mj-section':
      dnd('content-structure').set(value)
      return value ? 'content-structure' : null
    case context.isContent:
      dnd('content').set(value)
      return value ? 'content' : null
  }
}
// Func@dndState

// Func@setDefaultStyle
function setDefaultStyle (data) {
  Object.assign(o.conf.textDefaultStyle.links, data.links)
  Object.assign(o.conf.textDefaultStyle.text, data.text)
  Object.assign(o.conf.componantsDefaultStyle.buttons, data.buttons)
  Object.assign(o.conf.componantsDefaultStyle.structures, data.structures)
  readingDirection().set(o.conf.textDefaultStyle.text.direction)
}
// Func@setDefaultStyle

// Func@buildDefaultStyleObject
function buildDefaultStylesObject () {
  return {
    links: o.conf.textDefaultStyle.links,
    text: o.conf.textDefaultStyle.text,
    ...o.conf.componantsDefaultStyle
  }
}
// Func@buildDefaultStyleObject

function setVersionId (id) {
  eventEmit('html-version-id', id)
  o.htmlVersion = id
}

function getVersionId() {
  return o.htmlVersion
}

function setHtmlBlocksVersions (blocks) {
  blocks.forEach(block => {
    block.unique_id = _uniqueId('bloc-')
  })
  o.htmlsBlocksVersions = blocks
}

function getHtmlBlocksVersions () {
  return o.htmlsBlocksVersions
}

function deleteHtmlBlocksVersions (elem) {
  const block = o.htmlsBlocksVersions.find(b => b.unique_id === elem.block.unique_id)
  const index = o.htmlsBlocksVersions.indexOf(block)
  o.htmlsBlocksVersions.splice(index, 1)
  if (o.outdatedBlocks.includes(block.id)) {
    o.outdatedBlocks.splice(o.outdatedBlocks.indexOf(block.id), 1)
    eventEmit('outdated-blocks-count', o.outdatedBlocks.length)
  }
  if (o.htmlsBlocksVersions.length === 0) {
    eventEmit('no-used-saved-block')
  }
}

function orderSavedBlocks (data) {
  data.forEach((section, index) => {
    if (section.block) {
      const block = o.htmlsBlocksVersions.find(b => b.unique_id === section.block.unique_id)
      if (block) {
        block.children_index = index
        const replaceIdx = o.htmlsBlocksVersions.indexOf(block)
        o.htmlsBlocksVersions[replaceIdx] = block
      }
    }
  })
}
function insertSavedBlock ({ index, removed, blocks_versions_id, mjml_json, unique_id }) {
  o.htmlsBlocksVersions.splice(index, removed, {
    children_index: index,
    blocks_versions_id,
    unique_id,
    mjml_json,
  })
  eventEmit('show-saved-block')
}

function updateBlockVersion({ mjml_json, id, block_id }, index) {
  const current = o.htmlsBlocksVersions.find(v => v.children_index === index)
  const idx = o.htmlsBlocksVersions.indexOf(current)
  o.htmlsBlocksVersions[idx].mjml_json = JSON.stringify(mjml_json)
  o.htmlsBlocksVersions[idx].blocks_versions_id = id
  o.htmlsBlocksVersions[idx].block_id = block_id
  if (o.outdatedBlocks.includes(current.id)) {
    o.outdatedBlocks.splice(o.outdatedBlocks.indexOf(current.id), 1)
    eventEmit('outdated-blocks-count', o.outdatedBlocks.length)
  }
}

function parentHost () {
  return {
    get () {
      return o.parentHost
    },
    set (value) {
      o.parentHost = value
    }
  }
}

function outdatedBlocks () {
  return {
    get () {
      return o.outdatedBlocks
    },
    set (value) {
      o.outdatedBlocks.push(value)
      eventEmit('outdated-blocks-count', o.outdatedBlocks.length)
    }
  }
}

function resetOutdatedBlocks() {
  o.outdatedBlocks = []
}

// trick to have the correct MJML render
function convertColumns(list) {
  list.forEach(child => {
    if (child.tagName === 'mj-column') {
      if (child.children[0] && child.children[0].tagName) {
        child.children = [ child.children ]
      }
    } else if (child.tagName === 'mj-section') {
      child.children = convertColumns(child.children)
    } else if (child.tagName === 'mj-group') {
      list = convertColumns(child.children)
    }
  })
  return list
}

export {
  globalstates,
  devmode,
  setOverview,
  mjmlOptimized,
  expandLeftPanel,
  closeEditPanel,
  openEditPanel,
  lockEditPanel,
  alertEditPanel,
  setActiveLeftPane,
  openBlockBuilderPanel,
  closeBlockBuilderPanel,
  lockBlockBuilderPanel,
  shiftShortcuts,
  dndState,
  mailPreview,
  mailTitle,
  mailLanguage,
  fileManager,
  qrcodeManager,
  setResourceWriteAuthorized,
  metaog,
  gotMetaOg,
  dnd,
  mjmlJson,
  userConfigLoaded,
  readingDirection,
  landingPageMode,
  savedBlockMode,
  openOpenGraphPanel,
  closeOpenGraphPanel,
  setEditingOpenGraphPanel,
  lockOpenGraphPanel,
  workSpaceMode,
  hideInvisibleElements,
  setGabaritId,
  currentGabaritId,
  setDefaultStyle,
  buildDefaultStylesObject,
  setVersionId,
  getVersionId,
  setHtmlBlocksVersions,
  getHtmlBlocksVersions,
  deleteHtmlBlocksVersions,
  orderSavedBlocks,
  insertSavedBlock,
  updateBlockVersion,
  parentHost,
  outdatedBlocks,
  resetOutdatedBlocks,
  convertColumns
}
