export default {
  isSmallSelector: false,
  contentIsHover: false,
  actions: [
    {
      type: 'drag',
      icon: 'mdi-move',
      desc: 't_drag_'
    },
    {
      type: 'unlink',
      icon: 'mdi-link-off',
      desc: 't_unlink_'
    },
    {
      type: 'update',
      icon: 'mdi-update',
      desc: 't_update_'
    },
    {
      type: 'edit-block',
      icon: 'mdi-pencil-box',
      desc: 't_edit-block_'
    },
    {
      type: 'edit',
      icon: 'mdi-edit',
      desc: 't_edit_'
    },
    {
      type: 'copy',
      icon: 'mdi-copy',
      desc: 't_copy_'
    },
    {
      type: 'delete',
      icon: 'mdi-delete',
      desc: 't_delete_'
    },
  ],
  information: [
    {
      type: 'hiddenOnDesktop',
      icon: 'mdi-no-desktop',
      desc: 't_hidden-on-desktop_'
    },
    {
      type: 'hiddenOnMobile',
      icon: 'mdi-no-mobile',
      desc: 't_hidden-on-mobile_'
    }
  ]
}
