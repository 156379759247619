export default {
  updateViewSmoothConf: false,
  select: {
    columHover: false,
    sectionHover: false,
    sections: []
  },
  config: {
    'mj-section': {
      'lock-axis': 'y',
      widths: [],
      hover: [],
      hasSectionHover: [],
      indexOnEdit: null,
      indexOnHover: null,
      orientation: 'vertical',
      dndStartedFromLibrary: false,
      elemOnDrag: false,
      dropPlaceholderOptions: {
        className: 'drop-preview',
        animationDuration: '150',
        showOnTop: true
      }
    },
    'mj-column': {
      widths: [],
      hover: [],
      hasContentHover: [],
      indexOnEdit: null,
      indexOnHover: null,
      'lock-axis': 'x',
      orientation: 'horizontal',
      elemOnDrag: false,
      dropPlaceholderOptions: {
        className: 'drop-preview',
        animationDuration: '150',
        showOnTop: true
      }
    }
  },
}
