import Vue from 'vue'
import Router from 'vue-router'
import i18n from './plugins/i18n.js'

// Components
import Editor from '@/components/views/editor/Editor.vue'

// Default style conf
import textDefaultStyle from '@/assets/config/default-style/text.conf'

// Component states
import editorStates from '@/components/views/editor/editor.state.js'

// Services
import {
  devmode,
  fileManager,
  qrcodeManager,
  mjmlOptimized,
  landingPageMode,
  workSpaceMode,
  setGabaritId,
  currentGabaritId,
  readingDirection,
  hideInvisibleElements,
  setResourceWriteAuthorized,
  parentHost
} from '@/services/states/states.service'
import { savedBlockMode } from './services/states/states.service.js'

Vue.use(Router)

// Func@getURLParams
/**
 * Get url params
 * @param  {Object}   to   (vueRouter to)
 * @param  {Object}   from (vueRouter from)
 * @param  {Function} next (vueRouter next)
 */
function getURLParams (to, from, next) {
  // Default states values
  devmode().set(false)
  savedBlockMode().set(false)
  landingPageMode().set(false)
  fileManager().set(false)
  qrcodeManager().set(false)
  workSpaceMode().set('desktop')
  hideInvisibleElements().set(false)
  setResourceWriteAuthorized(false)
  editorStates.displayMjmlRendering = false

  // Set DND config
  for (const key in to.query) {
    switch (key) {
      case 'devmode':
        if (process.env.DEV_MODE) {
          editorStates.displayMjmlRendering = true
          devmode().set(true)
          mjmlOptimized().set(false)
          break
        }
        mjmlOptimized().set(true)
        break
      case 'filemanager-on':
        fileManager().set(true)
        break
      case 'qrcodemanager-on':
        qrcodeManager().set(true)
        break
      case 'edit-resource-on':
        setResourceWriteAuthorized(true)
        break
      case 'landing-page-on':
        landingPageMode().set(true)
        break
      case 'mobile-preview-on':
        workSpaceMode().set('mobile')
        break
      case 'hide-invisible-elements':
        hideInvisibleElements().set(true)
        break
      case 'block-editor-on':
        savedBlockMode().set(true)
        break
      case 'parent-host':
        parentHost().set(to.query['parent-host'])
        break
      case 'l':
        i18n.locale = to.query.l
        break
    }
  }

  // Default reading direction
  readingDirection().set(textDefaultStyle.text.direction)

  if (to.query['template-id']) {
    setGabaritId().set(to.query['template-id'])
  } else {
    // unset gabarit
    setGabaritId().set(null)
    currentGabaritId().set(null)
  }

  if (to.params.id || to.params.block_id) {
    Vue.api.token().update(to.query.t)
    Vue.api.clientConfig().load().then(next())
  } else {
    next()
  }
}
// Func@getURLParams

// Object@routes
const routes = {
  routes: [
    {
      path: '/:id?',
      name: 'editor',
      component: Editor
    }
  ]
}
// Object@routes

const router = new Router(routes)
router.beforeEach(getURLParams)

export default router
