// Configs
import textDefaultStyle from '@/assets/config/default-style/text.conf'

// Services
import { readingDirection } from '@/services/states/states.service'

// Library
import {
  get as _get,
  max as _max,
  merge as _merge,
  uniqueId as _uniqueId,
  cloneDeep as _cloneDeep,
  debounce as _debounce
} from 'lodash-es'

// Func@applyDrag
/**
 * Drag & Drop function
 * @param  {Array} arr         (Destination)
 * @param  {Object} dragResult (Element parametters)
 *
 * @return {Array}             (Updated list)
 */
function applyDrag (arr, dragResult) {
  const { removedIndex, addedIndex, payload } = dragResult
  if (removedIndex === null && addedIndex === null && !payload) return arr

  const result = [...arr]
  let itemToAdd = payload
  if (removedIndex === null && addedIndex !== null) {
    const payloadData = _cloneDeep(_get(payload, 'data', payload))
    payloadData.id = _uniqueId(`${payloadData.tagName}_`)
    switch (payloadData.tagName) {
      case 'mj-section':
        payloadData.children.map(column => {
          column.id = _uniqueId(`${column.tagName}_`)
        })
        break
      case 'mj-carousel':
        payloadData.children.map(carouselImage => {
          carouselImage.id = _uniqueId(`${payloadData.id}-image_`)
        })
        break
    }

    result.splice(addedIndex, 0, payloadData)

    return result
  }

  if (removedIndex !== null) {
    itemToAdd = result.splice(removedIndex, 1)[0]
  }

  if (addedIndex !== null) {
    result.splice(addedIndex, 0, itemToAdd)
  }

  return result
}
// Func@applyDrag

// Func@debounce
/**
 * Generate a debounce of a function depending his type
 * @param  {String} options.type           (type of debounce)
 * @param  {Object} options.service        (service containing function)
 * @param  {Function\String} options.func  (function from service or function)
 * @param  {Object} options.param          (function arguments)
 */
const debounces = {}
const debounce = ({ type = 'global', params = [], service, func, delay = 600 }) => {
  const funcIsFunction = typeof func === 'function' || typeof _get(service, func) === 'function'
  const typeIsString = typeof type === 'string'

  if (!funcIsFunction) {
    console.error('Utils Service: Wrong parametters, "func" or "service[func]" must be a function')
    return
  }

  if (!typeIsString) {
    console.error('Utils Service: Wrong parametters, "type" must be a string')
    return
  }

  if (!debounces[type]) {
    debounces[type] = _debounce(({ service, func, params }) => {
      if (service) service[func](...params)
      else func(...params)
    }, delay)
  }

  debounces[type]({ type, service, func, params })
}
// Func@debounce

// Func@eventEmit
/**
 * Emit an event which could be catched by
 * parent (window.parent) applications (as factorly)
 */
function eventEmit (message, value) {
  parent.postMessage({
    call: `dnd-editor:${message}`,
    value
  }, '*')
}
// Func@eventEmit

// Func@base64toFile
/**
 * Convert base 64 encoded string to file
 * @param  {String} url
 * @param  {String} filename
 * @param  {String} mimeType
 *
 * @return {File}          Promise with file
 */
function base64toFile (url, filename, mimeType) {
  mimeType = mimeType || (url.match(/^data:([^;]+);/) || '')[1]
  return (fetch(url) // nosemgrep
    .then(function (res) { return res.arrayBuffer() })
    .then(function (buf) { return new File([buf], filename, { type: mimeType }) })
  )
}
// Func@base64toFile

// Func@ckTranslator
/**
 * ckEditor content translator between MJML and CKE
 * @param  {String} opt.entry (Content to convert)
 * @param  {String} opt.dest  (Desination: 'MJML' / 'CKE')
 *
 * @return {String}           (Translated content)
 */
function ckTranslator ({ entry, dest, options = {} }) {
  const toRTL = options.isRtl && options.onSwitch
  const toLTR = !options.isRtl && options.onSwitch
  const keepRTL = options.isRtl && !options.onSwitch
  const keepLTR = !options.isRtl && !options.onSwitch

  let fragmentIn, fragmentRegExIn
  let fragmentOut = '</span><br class="dnd">'
  let fragmentRegExOut = /<\/span><br class="dnd">/gm

  // define fragments
  switch (true) {
    case (keepLTR || toRTL) && dest === 'CKE':
    case (keepLTR || toLTR) && dest === 'MJML':
    case (keepLTR || toLTR) && options.isEnforcedLTR && dest === 'MJML': 
    case (keepLTR || toRTL) && options.isEnforcedLTR && dest === 'CKE': {
      fragmentIn = '<span class="dnd" dir="ltr" style="display: inline-block;'
      fragmentRegExIn = /<span class="dnd" dir="ltr" style="display: inline-block;/gm
      break
    }
    case (keepRTL || toRTL) && dest === 'MJML':
    case (keepRTL || toLTR) && dest === 'CKE': {
      fragmentIn = '<span class="dnd" dir="rtl" style="display: inline-block;'
      fragmentRegExIn = /<span class="dnd" dir="rtl" style="display: inline-block;/gm
      break
    }
  }

  switch (dest) {
    case 'MJML': {
      let updatedEntry
      // Replace <p> (not supported by MJML button)
      updatedEntry = entry
        .replace(/<ul>/gm, '<ul style="margin:0;padding:0;">')
        .replace(/<ol>/gm, '<ol style="margin:0;padding:0;">')
        .replace(/<li>/gm, '<li style="margin:0 0 0 30px;padding:0;">')
        .replace(/<span>&nbsp;<\/span>/gm, '<span>&nbsp;</span>')
        .replace(/<p style="/gm, fragmentIn)
        .replace(/<p>/gm, fragmentIn + '">')
        .replace(/<\/p>/gm, fragmentOut)

      // Add text-decoration none inline for better mail compatibility
      const hasUnderline = _get(textDefaultStyle, 'links.options', [])
        .find(link => link.name === 'format-underline' && link.active)
      if (!hasUnderline) {
        updatedEntry = updatedEntry.replace(/<a /gm, '<a style="text-decoration: none;"')
      }
      updatedEntry = updatedEntry.slice(0, updatedEntry.lastIndexOf('<br class="dnd">'))
      return updatedEntry
    }
    case 'CKE': {
      if (entry.length > 0 && entry.lastIndexOf('<br class="dnd">') !== entry.length - 16) {
        entry += '<br class="dnd">'
      }

      let updatedEntry = entry
        .replace(/<span>&nbsp;<\/span>/gm, '<span>&nbsp;</span>')
        .replace(/<ul style="margin:0;padding:0;">/gm, '<ul>')
        .replace(/<ol style="margin:0;padding:0;">/gm, '<ol>')
        .replace(/<li style="margin:0 0 0 30px;padding:0;">/gm, '<li>')
        .replace(fragmentRegExIn, '<p style="')
        .replace(fragmentRegExOut, '</p>')
        .replace(/<p style="">/gm, '<p>')
        .replace(/<a style="text-decoration: none;"/gm, '<a ')

      // fix for default line height
      if (updatedEntry.includes('<p style=""><p ')) {
        updatedEntry = updatedEntry
        .replace('<p style=""><p ', '<p ')
        .replace('</p></p> ', '</p>')
      }
      return updatedEntry
    }
  }
}


function ckTranslatorMjText ({ entry, dest, options = {} }) {
  const toRTL = options.isRtl && options.onSwitch
  const toLTR = !options.isRtl && options.onSwitch
  const keepRTL = options.isRtl && !options.onSwitch
  const keepLTR = !options.isRtl && !options.onSwitch

  let fragmentIn, fragmentRegExIn
  let fragmentOut = '</p>'
  let fragmentRegExOut = /<\/p>/gm

  // define fragments
  switch (true) {
    case (keepLTR || toRTL) && dest === 'CKE':
    case (keepLTR || toLTR) && dest === 'MJML':
    case (keepLTR || toLTR) && options.isEnforcedLTR && dest === 'MJML': 
    case (keepLTR || toRTL) && options.isEnforcedLTR && dest === 'CKE': {
      fragmentIn = '<p class="dnd" dir="ltr" style="display: inline-block;'
      fragmentRegExIn = /<p class="dnd" dir="ltr" style="display: inline-block;/gm
      break
    }
    case (keepRTL || toRTL) && dest === 'MJML':
    case (keepRTL || toLTR) && dest === 'CKE': {
      fragmentIn = '<p class="dnd" dir="rtl" style="display: inline-block;'
      fragmentRegExIn = /<p class="dnd" dir="rtl" style="display: inline-block;/gm
      break
    }
  }

  switch (dest) {
    case 'MJML': {
      let updatedEntry
      // Replace <p> (not supported by MJML button)
      updatedEntry = entry
        .replace(/<ul>/gm, '<ul style="margin:0;padding:0;">')
        .replace(/<ol>/gm, '<ol style="margin:0;padding:0;">')
        .replace(/<li>/gm, '<li style="margin:0 0 0 30px;padding:0;">')
        .replace(/<span>&nbsp;<\/span>/gm, '<span>&nbsp;</span>')
        .replace(/<p style="/gm, fragmentIn)
        .replace(/<p>/gm, fragmentIn + '">')
        .replace(/<\/p>/gm, fragmentOut)

      // Add text-decoration none inline for better mail compatibility
      const hasUnderline = _get(textDefaultStyle, 'links.options', [])
        .find(link => link.name === 'format-underline' && link.active)
      if (!hasUnderline) {
        updatedEntry = updatedEntry.replace(/<a /gm, '<a style="text-decoration: none;"')
      }
      return updatedEntry
    }
    case 'CKE': {
      let updatedEntry = entry
        .replace(/<span>&nbsp;<\/span>/gm, '<span>&nbsp;</span>')
        .replace(/<ul style="margin:0;padding:0;">/gm, '<ul>')
        .replace(/<ol style="margin:0;padding:0;">/gm, '<ol>')
        .replace(/<li style="margin:0 0 0 30px;padding:0;">/gm, '<li>')
        .replace(fragmentRegExIn, '<p style="')
        .replace(fragmentRegExOut, '</p>')
        .replace(/<p style="">/gm, '<p>')
        .replace(/<a style="text-decoration: none;"/gm, '<a ')

      // fix for default line height
      if (updatedEntry.includes('<p style=""><p ')) {
        updatedEntry = updatedEntry
        .replace('<p style=""><p ', '<p ').replace('</p>', '')
      }
      return updatedEntry
    }
  }
}
// Func@ckTranslator

// Func@colorsWithLabel
/**
 * Formating colors for ckEditor
 * @param  {Array} colors
 * @return {Array}
 */
function colorsWithLabel (colors) {
  return colors.map(color => {
    if (color.label) return color
    return {
      label: color,
      color: color
    }
  })
}
// Func@colorsWithLabel

// Func@componentBuilder
/**
 * Builder used to generate MJML component (JSON format)
 * @param  {Number}  options.childrenNbr (nbr of children to generate)
 * @param  {String}  options.content     (Content of the component)
 * @param  {Boolean} options.withId      (When we need to generate id)
 * @param  {String}  options.componentId (Component id including context ex: mj-image#qrcode)
 * @param  {String}  options.parentId    (Parent id)
 * @param  {Object}  options.overwrite   (overwrite)
 *
 * @return {Object}                      (JSON MJML component)
 */
async function componentBuilder ({
  childrenNbr = 1,
  overwrite = {},
  content = null,
  withId = false,
  componentId,
  parentId
}) {
  try {
    let dndComponentId
    const context = componentId.includes('#') ? componentId.split('#').pop() : null
    const APIComponentId = componentId.includes('#') ? componentId.split('#').shift() : componentId
    const resource = `${APIComponentId}.conf`
    const module = await import(/* webpackMode: "eager" */'@/assets/config/mjml-components/' + resource + '.json')
    const conf = _cloneDeep({ ...module })
    const subComponents = _get(conf, 'sub_components')
    const apiTagVersion = _get(conf, 'api_tag')
    const dataToAssign = _get(overwrite, apiTagVersion)

    // Detect the dndComponentId when the same component is used for mutliple
    // DND components and select the correct subconfig when specified
    // (ex: mj-image#qrcode, qrcode is the dnd component id, derived from mj-image component)
    if (context && (conf.modifiers[context] || conf.attributes[context])) {
      dndComponentId = context
      conf.attributes[dndComponentId]
        ? conf.attributes[dndComponentId]['css-class'] = `dcid_${dndComponentId}`
        : conf.attributes.default['css-class'] = `dcid_${dndComponentId}`
    }

    /** * Create JSON MJML component **/
    const component = {
      tagName: apiTagVersion,
      attributes: _cloneDeep(conf.attributes[dndComponentId] || conf.attributes.default),
      children: []
    }

    if (withId) component.id = _uniqueId(`${parentId ? parentId + '-' + APIComponentId : APIComponentId}_`)
    if (conf.content) {
      const defaultContent = content || conf.content
      if (readingDirection().get() === 'rtl') component.attributes['css-class'] += 'is-rtl'
      component.content = readingDirection().get() === 'rtl' 
        ? `<span class="dnd" dir="rtl" style="display: inline-block;">${defaultContent}</span>`
        : `<span class="dnd" dir="ltr" style="display: inline-block;">${defaultContent}</span>`
      if (APIComponentId === 'mj-text') {
        component.content = readingDirection().get() === 'rtl' 
        ? `<p class="dnd" dir="rtl" style="display: inline-block;">${defaultContent}</p>`
        : `<p class="dnd" dir="ltr" style="display: inline-block;">${defaultContent}</p>`
      }
    }
    if (dataToAssign) _merge(component, dataToAssign)
    /** * End JSON MJML component **/

    if (subComponents) {
      const mjSubComponents = await Promise.all(subComponents.map(async subComponentId => {
        const payload = { componentId: subComponentId, content, withId, overwrite }
        return componentBuilder(payload)
      }))

      for (let i = 0; i < childrenNbr; i++) {
        const list = mjSubComponents.map(c => {
          const subComponentId = c.tagName.includes('#') ? c.tagName.split('#').shift() : c.tagName
          if (withId) c.id = _uniqueId(`${component.id + '-' + subComponentId}_`)
          const overwriteList = _get(overwrite, `list-${subComponentId}`)
          if (overwriteList) _merge(c, overwriteList[i])
          return _cloneDeep(c)
        })
        component.children.push(...list)
      }
    }

    return _cloneDeep(component)
  } catch (err) {
    console.error(err)
  }
}
// Func@componentBuilder

// Func@rgbToHex
/**
 * Convert color from rgb to hex format
 * @param  {String} rgbColor (color to translate)
 *
 * @return {String}          (Hex color)
 */
function rgbToHex (rgbColor) {
  let c = rgbColor.match(/\d+(\.\d+)?%?/g)
  if (c) {
    c = c.slice(0, 3).map(function (next) {
      let itm = next
      if (itm.indexOf('%') !== -1) {
        itm = Math.round(parseFloat(itm) * 2.55)
      }
      if (itm < 0) itm = 0
      if (itm > 255) itm = 255
      itm = Math.round(itm).toString(16)
      if (itm.length === 1) itm = '0' + itm
      return itm
    })
    return '#' + c.join('').toLowerCase()
  }
  return ''
}
// Func@rgbToHex

/**
 * Calculate height of each element then return the greatest one
 * @param {Collection} elements ()
 * @param {Sring} cssSelector ()
 *
 * @returns {Integer or null}
 */
function getGreatestHeight ({ elements, cssSelector }) {
  const elementsHeight = []
  elements.forEach(column => {
    let size = 0
    column.$el
      .querySelectorAll(cssSelector)
      .forEach(content => {
        size += content.offsetHeight
      })
    elementsHeight.push(size)
  })

  return _max(elementsHeight) || null
}

export {
  applyDrag,
  eventEmit,
  debounce,
  base64toFile,
  ckTranslator,
  ckTranslatorMjText,
  colorsWithLabel,
  componentBuilder,
  getGreatestHeight,
  rgbToHex
}
