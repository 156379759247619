// States & Config
import states from './template-structure.state.js'

// Services
import { applyDrag, getGreatestHeight } from '@/services/utils/utils.service'
import { globalstates, dndState, openEditPanel, closeEditPanel, hideInvisibleElements, insertSavedBlock, orderSavedBlocks, deleteHtmlBlocksVersions, parentHost, updateBlockVersion, landingPageMode } from '@/services/states/states.service'
import { getStyles } from '@/services/dnd-style-engine/dnd-style-engine.service'
import mjBodyConf from '@/assets/config/mjml-components/mj-body.conf.json'
// Components
import { Container, Draggable } from 'vue-smooth-dnd'
import MdiClose from 'vue-material-design-icons/Close.vue'
import MdiState from 'vue-material-design-icons/AlphaSBox.vue'
import MdiDelete from 'vue-material-design-icons/Delete.vue'
import MdiEditStructure from 'vue-material-design-icons/SquareEditOutline.vue'
import MdiDuplicateStructure from 'vue-material-design-icons/ContentDuplicate.vue'
import TemplateContent from '@/components/standalone/template-content/Template-content.vue'
import WorkspaceSelector from '@/components/standalone/workspace-selector/Workspace-selector.vue'

import { parseTemplate, generateSavedBlockStructure } from '@/services/dnd-template/dnd-template.service'

// Config file
import shortcuts from '@/assets/config/shortcuts/shortcuts.conf'

// Libraries
import {
  get as _get,
  filter as _filter,
  isArray as _isArray,
  uniqueId as _uniqueId,
  cloneDeep as _cloneDeep,
  flattenDeep as _flattenDeep
} from 'lodash-es'

/**
 * Vue declaration ------------------------------------
 */

// Name
const name = 'Template-structure'

// Properties
const props = {
  data: Array,
  mode: String,
  mobile: Boolean,
  parentOnDrag: Boolean,
  displayStates: Boolean,
  keepContentActive: Boolean,
  attributes: Object,
  parentSelectorHover: Boolean,
  parentColNbr: Number,
  isNested: Boolean
}

// Vue@data
const data = function () {
  return {
    workspaceStates: _get(globalstates, 'workspace'),
    leftPanel: _get(globalstates, 'leftPanel', {}),
    states: _cloneDeep(states),
    shortcuts
  }
}
// Vue@data

// Vue@subComponents
const components = {
  TemplateColumns: () => import('@/components/standalone/template-structure/Template-structure.vue'),
  MdiDuplicateStructure,
  MdiEditStructure,
  WorkspaceSelector,
  TemplateContent,
  Container,
  Draggable,
  MdiDelete,
  MdiState,
  MdiClose
}
// Vue@subComponents

// Methods
const methods = {

  // Func@config
  /**
   * Config getter/setter to get states keys depending
   * the structure element (section / column)
   *
   * @return {Value} (can be all type of value)
   */
  config (type) {
    return {
      get: key => {
        return _get(this.states.config[type || this.getType], key)
      },
      set: (key, value) => {
        this.$set(this.states.config[type || this.getType], key, value)
      }
    }
  },
  // Func@config

  getComponentName (content) {
    let dndComponentId
    const contentClass = _get(content, 'attributes.css-class', '')
    const dndComponentIdClass = contentClass.split(' ').find(c => c.includes('dcid_'))
    if (dndComponentIdClass) dndComponentId = dndComponentIdClass.split('dcid_').pop()

    return dndComponentId ? `${content.tagName}-${dndComponentId}` : content.tagName
  },

  // Func@updateDndState
  /**
   * Set drag & drop state for all the workspace,
   * this func is triggered by all drag & drop container
   * whatever the source of the drag
   *
   * @param  {Boolean} options.isSource  (to identify if the the trigger is the source)
   * @param  {Object}  options.payload   (data when new element on drag)
   * @param  {Boolean} options.value     (is drag active ? or drag end ?)
   * @param  {Boolean} options.isContent (explicite source cause this.isContent unavailable)
   */
  updateDndState ({ isSource, payload, value }) {
    this.config().set('hover', [...this.data.map(() => false)]) // Reset children hover state

    // Set drag and drop global state
    if (!isSource) return
    const componentId = _get(payload, 'data.tagName') || _get(payload, 'data.0.tagName')
    const context = { isSection: this.isSection, isColumn: this.isColumn }
    dndState({ isSource, componentId, value, context })

    // Set on drag for this row elem or this column elem
    this.config().set('elemOnDrag', value)
  },
  // Func@updateDndState

  // Func@getStyles
  /**
   * Get structure MJML style
   * @param  {Object} element (MJML object)
   *
   * @return {String}         (CSS style)
   */
  getStyles (element) {
    return getStyles(element)
  },
  // Func@getStyles

  // Func@getParentData
  /**
   * Decorate parent data object
   * @param  {Object} mjml (mjml object)
   *
   * @return {Object}      (parent data)
   */
  getParentData (mjml) {
    return {
      mjml,
      columns: this.data.length
    }
  },
  // Func@getParentData

  // Func@isOnDrag
  /**
   * [isOnDrag description]
   * @return {Boolean} [description]
   */
  isOnDrag (level) {
    const dragState = this.workspaceStates.drag
    return level
      ? dragState.level === level
      : (this.parentOnDrag || this.config().get('elemOnDrag'))
  },
  // Func@isOnDrag

  // Func@selectorActions
  /**
   * Defining actions available for the current selector
   * @param  {Object}  elem  (Focused element)
   * @param  {Integer} index (Element position)
   * @return {Array}         (Actions list)
   */
  selectorActions (elem, index) {
    let actions = this.leftPanel.editPanel.active ? [] : ['drag']
    const hasDeleteAction =
      !this.isOnEdit(index) &&
      !this.leftPanel.blockBuilderPanel.active &&
      ((this.isColumn && this.data.length > 1) || this.isSection)

    // TODO: create a generic methode for each consumable
    const hasMjScratchIn = JSON.stringify(elem).includes('"tagName":"mj-scratch"')
    const hasDuplicateAction =
      !hasMjScratchIn &&
      !this.isOnEdit(index) &&
      !this.leftPanel.blockBuilderPanel.active &&
      !((this.isColumn && Boolean(elem.attributes.width)) || (this.isColumn && this.data.length === 6))

    const hasEditButton = !this.isOnEdit(index) && !this.leftPanel.blockBuilderPanel.active

    if (hasEditButton) actions.push('edit')
    if (hasDuplicateAction) actions.push('copy')
    if (hasDeleteAction) actions.push('delete')

    if (this.isSavedBlock(elem)) {
      actions = [
        'drag',
        'unlink',
        'delete'
      ]
      if (!elem.block.is_last_version) {
        actions.splice(2, 0, 'update')
      }
      if (this.hasResourceWrite) {
        actions.splice(3, 0, 'edit-block')
      }
    }

    return actions
  },
  // Func@selectorActions

  // Func@onActionClick
  /**
   * Event mediator for selector actions
   * @param  {String}  actionType (action name)
   * @param  {Integer} elemIndex  (element index)
   * @param  {Object}  elem       (element)
   */
  onActionClick (actionType, elemIndex, elem) {
    switch (actionType) {
      case 'check': {
        this.onSectionSelect(elemIndex)
        break
      }
      case 'edit': {
        this.openEdit(elemIndex)
        break
      }
      case 'copy': {
        this.duplicateStructure(elemIndex, elem)
        orderSavedBlocks(this.data)
        break
      }
      case 'edit-block': {
        window.open(`${parentHost().get()}/blocks/${elem.block.block_id}/edit/`)
        break
      }
      case 'delete': {
        const editModeOpen = this.leftPanel.editPanel.active
        const childrenIds = this.getAllChildrenIds(elem)
        const hasChildrenOnEdit = editModeOpen && childrenIds.includes(this.leftPanel.editPanel.id)
        this.deleteStructure(elemIndex)
        if (elem && elem.block) {
          deleteHtmlBlocksVersions(elem)
          this.$emit('save')
        }
        orderSavedBlocks(this.data)
        if (hasChildrenOnEdit) this.closeEdit()
        break
      }
      case 'update': {
        this.getLastVersion(elem.block.block_id, elemIndex)
        break
      }
      case 'unlink': {
        deleteHtmlBlocksVersions(elem)
        this.replaceBlockWithSection(elemIndex)
        orderSavedBlocks(this.data)
        break
      }
    }
  },
  async getLastVersion(id, index) {
    this.api.blocks(id).loadMjml()
    .then(response => {
      updateBlockVersion(response, index)
      const data = generateSavedBlockStructure(response.mjml_json)
      this.data[index] = {
        ...data,
        block: {
          ...this.data[index].block,
          is_last_version: true,
          id: response.id,
          mjml_json: response.mjml_json
        }
      }
      this.$emit('save')
    })
  },

  async replaceBlockWithSection(index) {
    const baseMjml =  {
      tagName: 'mjml',
      attributes: {},
      children: [
        {
          tagName: 'mj-body',
          attributes: _get(mjBodyConf, 'attributes.default', {}),
          children: []
        },
        {
          tagName: 'mj-head',
          attributes: {},
          children: []
        }
      ]
    }
    const basicMjml = Object.assign({}, baseMjml)
    basicMjml.children[0].children = this.data[index].block.mjml_json
    const mjml = await parseTemplate({ template: basicMjml }).toDnd()
    const section = mjml.children[0].children
    section.forEach((sec, idx) => {
      const newElem = sec
      newElem.id = _uniqueId(`${newElem.tagName}_`)
      newElem.attributes.responsive = sec.attributes.responsive
      if (idx === 0) {
        this.data.splice(index, 1, newElem)
      } else {
        this.data.splice(index + idx, 0, newElem)
      }
    })
    this.$emit('save')
  },
  // Func@onActionClick

  // Func@onSectionSelect
  /**
   * On section select, update selected position list and generate list
   * of sections used to build blocks library
   * @param  {Integer} index (index of selected)
   */
  onSectionSelect (index) {
    const sections = this.states.select.sections
    sections[index].checked = !sections[index].checked

    this.$set(
      this.leftPanel.blockBuilderPanel, 'blocks',
      _filter(this.data, (item, sectionIndex) => sections[sectionIndex].checked)
    )
  },
  // Func@onSectionSelect

  // Func@openEdit
  /**
   * Open edit panel
   * @param  {Number} index (Position)
   */
  openEdit (index) {
    this.config().set('indexOnEdit', index)
    openEditPanel({ contents: this.data, index })
  },
  // Func@openEdit

  // Func@closeEdit
  /**
   * Close edit panel
   */
  closeEdit () {
    this.config('mj-column').set('indexOnEdit', null)
    this.config('mj-section').set('indexOnEdit', null)
    closeEditPanel()
  },
  // Func@closeEdit

  // Func@duplicateStructure
  /**
   * Duplicate the current structure, refresh id, and add the
   * clone at the next index
   * @param  {Integer} index (Index of the element to duplicate)
   * @param  {Object}  elem  (Element to duplicate)
   */
  duplicateStructure (index, elem) {
    const newElem = _cloneDeep(elem)

    // Generate new id for duplicated section/column/content
    newElem.id = _uniqueId(`${newElem.tagName}_`)
    newElem.children.map(child => {
      if (this.isSection) {
        child.id = _uniqueId(`${child.tagName}_`)
        child.children.map(state => {
          state.map(content => {
            content.id = _uniqueId(`${content.tagName}_`)
            return content
          })
        })
      } else {
        child.map(content => {
          content.id = _uniqueId(`${content.tagName}_`)
          return content
        })
      }
    })

    this.data.splice(index + 1, 0, newElem)
  },
  // Func@duplicateStructure

  // Func@deleteStructure
  /**
   * Remove selected structure from the data
   * @param  {Integer}   index  (Position)
   */
  deleteStructure (index) {
    const custumWidth = _get(this.data[index], 'attributes.width', null)
    this.data.splice(index, 1)
    switch (true) {
      // Is section or is auto-calculated width
      case this.isSection || !custumWidth: {
        return
      }

      // Result is one column
      case this.data.length === 1: {
        this.data[0].attributes.width = ''
        return
      }

      // Result is more than one column
      default: {
        const newCurrent = this.data[index] || this.data[index - 1]
        newCurrent.attributes.width = `${parseInt(newCurrent.attributes.width) + parseInt(custumWidth)}%`
      }
    }
  },
  // Func@deleteStructure

  // Func@getAllChildrenIds
  /**
   * Get all children column, and contents, ids of the selected element
   * @param  {Object} elem (element to test)
   * @return {Array}       (ids list)
   */
  getAllChildrenIds (elem) {
    if (this.isSection) {
      const columnsFull = elem.children.filter(col => _get(col.children, '0.length'))
      const columIds = columnsFull.map(el => el.id)
      const contentIds = _flattenDeep(columnsFull.map(col => {
        return _flattenDeep(col.children).map(content => content.id)
      }))

      return [...columIds, ...contentIds]
    }

    return _flattenDeep(elem.children).map(content => content.id)
  },
  // Func@getAllChildrenIds

  // Func@onDrop
  /**
   * To get the droped list and replace the current
   * @param  {Object} dropResult (D&D params)
   */
  async onDrop (dropResult) {
    if (!dropResult) return
    let data;
    let version;
    let bloc_unique_id;
    if (dropResult.payload && !_get(dropResult, 'payload.data')) {
      try {
        version = await this.api
          .blocks(dropResult.payload.id)
          .loadMjml()
        if (landingPageMode().get()) {
          data = version.mjml_json
        } else {
          if (version && version.id) {
            data = [generateSavedBlockStructure(version.mjml_json)]
            bloc_unique_id = _uniqueId('bloc-')
            data.forEach((section) => {
              section.block = {
                unique_id: bloc_unique_id,
                id: dropResult.payload.id,
                block_id: version.block_id,
                name: dropResult.payload.name,
                is_last_version: true,
                mjml_json: typeof version.mjml_json === 'string' ? JSON.parse(version.mjml_json): version.mjml_json,
              }
            })
          }
        }
      } finally {
        if (!landingPageMode().get()) {
          insertSavedBlock({
            index: dropResult.addedIndex,
            removed: dropResult.removedIndex || 0,
            blocks_versions_id: version.id,
            mjml_json: typeof version.mjml_json === 'string' ? JSON.parse(version.mjml_json): version.mjml_json,
            unique_id: bloc_unique_id,
            is_last_version: true,
          })
        }
      }
    } else {
      data = _get(dropResult, 'payload.data')
    }
    const isListOfSections = _isArray(data)
    if (isListOfSections) {
      let addedIndex = dropResult.addedIndex
      const removedIndex = dropResult.removedIndex

      data.forEach(payload => {
        const newArray = applyDrag(this.data, { removedIndex, payload, addedIndex })
        this.data.splice(0, this.data.length, ...newArray)
        addedIndex++
      })
      orderSavedBlocks(this.data)
      return
    }
    const newArray = applyDrag(this.data, dropResult)
    this.data.splice(0, this.data.length, ...newArray)
    orderSavedBlocks(this.data)
  },
  // Func@onDrop

  // Func@getPayload
  /**
   * Get DND payload
   * @param  {Number} index (position)
   *
   * @return {Object}       (payload)
   */
  getPayload (index) {
    return this.data[index]
  },
  // Func@getPayload

  // Func@getColumnMinHeight
  /**
   * Get maximum height of a column in order
   * to keep DnD container available on full height
   * for empty columns
   *
   * @return {String}       (min-height property)
   */
  getColumnMinHeight () {
    if (!this.$refs.columns) return

    const minHeight = 60
    const greatestHeight = getGreatestHeight({
      elements: this.$refs.columns,
      cssSelector: ':scope > .template-content > .smooth-dnd-container > .smooth-dnd-draggable-wrapper'
    })

    return greatestHeight || minHeight
  },
  // Func@getColumnMinHeight

  // Func@calculateWidth
  /**
   * Calculate width of each column in real time
   * @returns {Array}
   */
  calculateWidth () {
    if (!this.$refs.columns) return
    setTimeout(() => {
      const widths = []
      this.$refs.columns
        .forEach(column => {
          widths.push(column.$el.clientWidth)
        })
      this.config().set('widths', widths)
    }, 250)
  },
  // Func@calculateWidth

  // Func@resizeColumns
  /**
   * Resize column
   */
  resizeColumns () {
    if (!this.$refs.columns) return
    setTimeout(() => {
      const minHeight = this.getColumnMinHeight()
      this.$refs.columns
        .forEach(column => {
          const cssClass = column.$el.getAttribute('class')
          if (cssClass.includes('mj-section')) return

          const attr = column.$el.getAttribute('style') || ''
          const attrArray = attr.split(';')
          const cleanArray = attrArray.filter(str => Boolean(str) && !str.includes('min-height'))
          const newStyle = `${cleanArray.join('_').replace(/_/g, ';')}; min-height: ${minHeight}px;`
          column.$el.setAttribute('style', newStyle)
        })
    }, 500)
  },
  // Func@resizeColumns

  // Func@getStructureClass
  /**
   * Get class depending structure (section or column)
   * @param  {Number} index (position)
   *
   * @return {String}       (class)
   */
  getStructureClass (index) {
    let typeClass = `${this.getType} `
    if (this.isColumn) {
      const hasContent = this.contents({ elem: this.data[index], removeDisabled: true }).length
      const hasCustumWidth = _get(this.data[index], 'attributes.width', false)

      if (!hasContent) typeClass += 'empty '
      if (hasCustumWidth) typeClass += 'custom-width '
      if (this.displayStates) typeClass += 'statesDisplayed '
      if (hasCustumWidth) return typeClass

      const percent = Math.floor(100 / this.data.length)
      typeClass += `mj-column-per-${percent} `
    }

    // If block builder panel is active, add class to set margin top bottom
    if (!this.isColumn && this.leftPanel.blockBuilderPanel.active) {
      typeClass += ' block-builder-active'
    }

    return typeClass
  },
  // Func@getStructureClass

  // Func@displaySelector
  /**
   * Condition to display or not the selector
   *
   * @return {Boolean} [description]
   */
  displaySelector (index) {
    // Hide selector if section is hidden on mobile / desktop
    if (this.isSection && hideInvisibleElements().get()) {
      const classes = this.data[index].attributes['css-class']
      const isHidden = (classes.includes('show-on-desktop') && this.isMobile) ||
                       (classes.includes('show-on-mobile') && !this.isMobile)
      return !isHidden
    }

    return (this.states.select.sections[index] && this.isSection) || this.isColumn
  },
  // Func@displaySelector

  // Func@minHeightSelect
  /**
   * Add class to elements if height is less than
   * 60px in order to display structure buttons on select
   * @param  {Number} index (Element index)
   */
  minHeightSelect (index) {
    if (!_get(this.$refs.columns, index)) return

    this.config().set('indexOnHover', index)

    const element = this.$refs.columns[index].$el
    const currentHeight = element.offsetHeight
    const sectionEl = this.isSection ? element : element.parentNode.parentNode.parentNode

    if (currentHeight > 60) {
      sectionEl.classList.remove('reduced-height')
      return
    }

    sectionEl.classList.add('reduced-height')
  },
  // Func@minHeightSelect

  // Func@contents
  /**
   * List of contents (not disabled)
   * @param  {Object} elem (current element)
   * @return {Array}       (List of contents)
   */
  contents ({ elem, removeDisabled = false }) {
    const contents = _isArray(elem.attributes.states)
    ? elem.children[elem.attributes.activeStateIndex]
    : elem.children
    if (contents) {
      const contentsWihtoutDiabled = contents.filter(content => {
        const cssClass = content.attributes['css-class']
        const isFallBackComponent = cssClass && cssClass.includes('fallback-')
        const isDisabledComponent = !this.isMobile && cssClass && cssClass.includes('fallback-desktop-false') ||
                                    this.isMobile && cssClass && cssClass.includes('fallback-mobile-false')
        return !isFallBackComponent || !isDisabledComponent
      })
      return removeDisabled ? contentsWihtoutDiabled : contents
    }
    return []

   
  },
  // Func@contents

  // Func@isOnEdit
  /**
   * Current section / column on edit
   * @return {Boolean}
   */
  isOnEdit (elem) {
    return elem.id === this.leftPanel.editPanel.id && this.leftPanel.editPanel.active
  },
  // Func@isOnEdit

  // Func@hoverState
  /**
   * Get and Set hover state for selectors
   * @param  {Boolean} options.children (hover from children)
   * @param  {Boolean} options.parent   (hover from parent)
   * @return {Boolean}
   */
  hoverState ({ children = false, parent = false }) {
    // Build state array
    const enableSet = (children && this.isColumn) || (parent && this.isSection)
    const updateStateArray = this.config().get('hover').length !== this.data.length
    if (updateStateArray) this.config().set('hover', [...this.data.map(() => false)])

    return {
      get: index => {
        return this.config().get('hover')[index]
      },
      set: (index, value) => {
        if (!enableSet) return
        this.config().set('hover', [...this.data.map((v, i) => index === i ? value : false)])
      }
    }
  },
  // Func@hoverState

  // Func@isBlockBuilderCheckedSection
  /**
   * Check if section is checked on blockbuilder state actice
   * @return {Boolean}
   */
  isBlockBuilderCheckedSection (index) {
    return this.isSection && this.leftPanel.blockBuilderPanel.active && this.states.select.sections[index].checked
  },
  // Func@isBlockBuilderCheckedSection

  // Func@selectorInformation
  /**
   * Defining available information for the current section selector
   * @param  {Object}  elem  (Focused element)
   * @return {Array}         (Information list)
   */
  selectorInformation (elem) {
    if (!this.isSection) return []
    const information = []
    const sectionClasses = _get(elem, 'attributes.css-class', '')

    if (sectionClasses.includes('show-on-mobile')) information.push('hiddenOnDesktop')
    if (sectionClasses.includes('show-on-desktop')) information.push('hiddenOnMobile')

    return information
  },
  // Func@selectorInformation

  // Func@autoResizeColumn
  /**
   * Resize columns
   */
  autoResizeColumn () {
    this.resizeColumns()
    setTimeout(() => this.data.forEach((col, index) => this.minHeightSelect(index)), 400)
  },
  // Func@autoResizeColumn

  isSavedBlock (elem) {
    return elem.block && elem.block.id.length > 0
  },
  getBlockName (elem) {
    if (elem.block) {
      return elem.block.name
    }
    return ''
  }
}

// Computed methods
const computed = {

  // Func@getType
  /**
   * Get the type
   *
   * @return {String} (mj-section or mj-column)
   */
  getType () {
    return _get(this.data[0], 'tagName', 'mj-section')
  },
  // Func@getType

  // Func@getGroupName
  /**
   * Get group name depending parent index
   *
   * @return {String} (mj-{type}-index or mj-{type})
   */
  getGroupName () {
    const dragState = this.workspaceStates.drag
    const sectionType = dragState.level ? `mj-${dragState.level}` : this.getType
    return this.isSection ? sectionType : `${this.getType}_${this._uid}`
  },
  // Func@getGroupName

  // Func@isSection
  /**
   * Is MJML section comparator
   *
   * @return {Boolean}
   */
  isSection () {
    return this.getType === 'mj-section'
  },
  // Func@isSection

  // Func@isColumn
  /**
   * Is MJML column comparator
   *
   * @return {Boolean} [description]
   */
  isColumn () {
    return this.getType === 'mj-column'
  },
  // Func@isColumn

  // Func@selectorMode
  /**
   * Define the selector mode
   * @return {String}
   */
  selectorMode () {
    return this.leftPanel.blockBuilderPanel.active ? 'checkbox' : 'standard'
  },
  // Func@selectorMode

  // Func@getSelectorMoveElement
  /**
   * Get selector for drag and drop move action
   * @return {String}
   */
  getSelectorMoveElement () {
    const moveAction = '.actions .drag'
    return `.workspace-selector.${this.getType.replace('mj-', '')} ${moveAction}`
  },
  // Func@getSelectorMoveElement

  // Func@getComponentClass
  /**
   * Generate component class for type (section|column), mobile (false|true),
   * if an elem of the list is on drag with the Type of the elem on drag
   *
   * @return {String}  (class)
   */
  getComponentClass () {
    const dragState = this.workspaceStates.drag
    const isResponsive = this.attributes.responsive || false
    let classes = `${this.getType.replace('mj-', '')}s mobile-${this.mobile && isResponsive} nbr-elem-${this.data.length} `

    if (this.mode) classes += ` mode-${this.mode}`
    if (this.isNested) classes += ' nested'
    if (dragState.active) classes += ` on-drag ${dragState.level ? dragState.level + '-on-drag' : ''}`
    if (this.leftPanel.editPanel.active) classes += ' edit-panel-open'

    if (this.isColumn) {
      const hasEmptyColumns = this.data.filter(column => column.children[0] && !column.children[0].length).length > 0
      if (hasEmptyColumns) classes += ' incomplete-line'
    }

    return classes
  },
  // Func@getComponentClass

  // Func@isMobile
  /**
   * If it's mobile from url or resize
   */
  isMobile () {
    return this.mobile || this.workspaceStates.mode === 'mobile'
  },
  // Func@isMobile

  // Func@targetDevice
  /**
   * Device the structure is displayed on
   * @return {String} (null if no specific device targetted)
   */
  targetDevice () {
    const elemClass = _get(this.attributes, 'css-class', '')
    if (elemClass.indexOf('show-on-desktop') > -1) return 'desktop'
    else if (elemClass.indexOf('show-on-mobile') > -1) return 'mobile'
    return null
  },
  // Func@targetDevice

  // Func@isActiveOnCurrentDevice
  /**
   * Current section / column visible for current device (mobile or desktop)
   * @return {Boolean}
   */
  isActiveOnCurrentDevice () {
    return !this.targetDevice ||
      (this.targetDevice === 'mobile' && this.mobile) ||
      (this.targetDevice === 'desktop' && !this.mobile)
  },
  // Func@isActiveOnCurrentDevice

  // Func@isDisabled
  /**
   * Current section / column disabled
   * @return {Boolean}
   */
  isDisabled () {
    return !this.keepContentActive &&
      (this.leftPanel.editPanel.active || !this.isActiveOnCurrentDevice)
  },
  // Func@isDisabled

  // Func@isHidden
  /**
   * Current section / column hidden
   * @return {Boolean}
   */
  isHidden () {
    const hideElements = hideInvisibleElements().get()
    return hideElements && !this.isActiveOnCurrentDevice
  },
  // Func@isHidden
  hasResourceWrite () {
    return globalstates.workspace.resourceWriteAuthorized
  },
}

// Vue@watchData
const watch = {

  /**
   * [isMobile description]
   * @return {Boolean} [description]
   */
  isMobile: function () {
    if (!this.isColumn) return
    this.states.updateViewSmoothConf = true
    this.config().set('orientation', this.isMobile ? 'vertical' : 'horizontal')
    this.config().set('lock-axis', this.isMobile ? 'y' : 'x')
    this.$nextTick(() => {
      this.states.updateViewSmoothConf = false
      if (!this.isMobile) this.autoResizeColumn()
    })
  },

  /**
   * Set edit index to null on edit panel close (from anywhere)
   * @param  {Boolean} val (panel open or close)
   */
  'editPanelState.id': function (id) {
    const ids = this.data.map(elem => elem.id)
    if (!id || !ids.includes(id)) {
      this.config('mj-column').set('indexOnEdit', null)
      this.config('mj-section').set('indexOnEdit', null)
    }
  },

  /**
   * Set selected section to empty on block builder panel close (from anywhere)
   * @param  {Boolean} val (panel open or close)
   */
  'leftPanel.blockBuilderPanel.active': function (val) {
    if (this.isColumn || val === true) return
    this.leftPanel.blockBuilderPanel.blocks.length = 0
    this.states.select.sections.forEach(section => {
      section.checked = false
    })
  },

  /**
   * Resize column on screen mode update
   */
  'leftPanel.fiftyPercentWidth': function () {
    if (this.isColumn && !this.isMobile) this.autoResizeColumn()
  },

  /**
   * Calculate nested columns width on parent column update
   */
  parentColNbr: function () {
    this.calculateWidth()
  },

  /**
   * Resize column on column data update
   * and update selectable state list for sections (used for blocks)
   */
  data: {
    handler (val) {
      if (this.isSection) {
        // Set selected position table
        const selectedSections = []
        val.forEach(() => selectedSections.push({ checked: false }))
        this.states.select.sections.splice(0, this.states.select.sections.length, ...selectedSections)

        if (!this.isMobile) this.autoResizeColumn()
        return
      }
      if (this.isColumn) this.calculateWidth()
      if (!this.isMobile) this.autoResizeColumn()
    },
    deep: true
  }
}
// Vue@watchData

// Func@removeWindowListener on destroy
/**
 * Remove window resize event listener
 */
function beforeMount () {
  if (!this.isColumn) return
  this.config().set('orientation', this.isMobile ? 'vertical' : 'horizontal')
  this.config().set('lock-axis', this.isMobile ? 'y' : 'x')
}
// Func@removeWindowListener

// Func@mounted
/**
 * Resize element on component init, usefull on
 * row duplication for instance
 */
function mounted () {
  if (this.isColumn) this.calculateWidth()
  if (!this.isMobile) this.autoResizeColumn()
}
// Func@mounted

// Vue component syntax
export default {
  name,
  data,
  props,
  watch,
  methods,
  mounted,
  computed,
  components,
  beforeMount
}
