// Component states
import states from './editor.state.js'

// Components
import Workspace from '@/components/parts/workspace/Workspace.vue'
import Overview from '@/components/parts/overview/Overview.vue'
import StructurePanel from '@/components/parts/structure-panel/Structure-panel.vue'
import GeneralPanel from '@/components/parts/general-panel/General-panel.vue'
import ContentPanel from '@/components/parts/content-panel/Content-panel.vue'
import EditPanel from '@/components/parts/edit-panel/Edit-panel.vue'
import DeepEditPanel from '@/components/parts/deep-edit-panel/Deep-edit-panel.vue'
import BlockBuilderPanel from '@/components/parts/block-builder-panel/Block-builder-panel.vue'
import OpenGraphPanel from '@/components/parts/open-graph-panel/Open-graph-panel.vue'
import MdiEmail from 'vue-material-design-icons/Email.vue'
import MdiStyle from 'vue-material-design-icons/Palette.vue'
import MdiStructure from 'vue-material-design-icons/DeveloperBoard.vue'
import MdiContent from 'vue-material-design-icons/Apps.vue'
import MdiSettings from 'vue-material-design-icons/Cog.vue'
import MdiViewQuilt from 'vue-material-design-icons/ViewQuilt.vue'
import MdiHeadline from 'vue-material-design-icons/ViewHeadline.vue'
import MdiEmailCheck from 'vue-material-design-icons/EmailCheck.vue'
import MdiJson from 'vue-material-design-icons/CodeBracesBox.vue'
import MdiHtml from 'vue-material-design-icons/CodeNotEqualVariant.vue'
import MdiClose from 'vue-material-design-icons/Close.vue'

// Config file
import shortcuts from '@/assets/config/shortcuts/shortcuts.conf'

// Services
import {  outdatedBlocks, landingPageMode, setActiveLeftPane } from '@/services/states/states.service'

// Library
import {
  get as _get,
  findIndex as _findIndex
} from 'lodash-es'
import { eventEmit } from '@/services/utils/utils.service.js'

/**
 * Vue declaration ------------------------------------
 */

// Name
const name = 'Editor'

// Vue@data
const data = function () {
  return {
    states,
    shortcuts,
    landingPageMode,
    showOutdatedBanner: false,
    hasClosedBanner: false,
  }
}
// Vue@data

// Vue@subComponents
const components = {
  MdiJson,
  MdiHtml,
  MdiEmail,
  MdiStyle,
  Overview,
  EditPanel,
  Workspace,
  MdiContent,
  MdiSettings,
  MdiHeadline,
  MdiViewQuilt,
  MdiClose,
  GeneralPanel,
  ContentPanel,
  MdiStructure,
  DeepEditPanel,
  MdiEmailCheck,
  StructurePanel,
  BlockBuilderPanel,
  OpenGraphPanel
}
// Vue@subComponents

// Methods
const methods = {

  // Func@switchPane
  /**
   * Switch pane mode (General/Structure/Contents)
   */
  switchPane () {
    const panels = this.states.leftPanels
    const index = _findIndex(panels, { name: this.states.leftPanel.active })
    const nextPane = _get(panels, `${index + 1}.name`, panels[0].name)

    setActiveLeftPane(nextPane)
  },
  // Func@switchPane

  getBannerStatus() {
    this.showOutdatedBanner = outdatedBlocks().get().length > 0 && !this.hasClosedBanner
  },

  closeBanner () {
    this.hasClosedBanner = true
    this.getBannerStatus()
    eventEmit('has-hidden-outdated-blocks-banner')
  },

  viewModifications () {
    eventEmit('open-saved-block-modifications-modal')
  }
}

const computed = {
  // Func@panelSize
  /**
   * Switch panel size depending leftPanel state
   * @return {String} (Size)
   */
  panelSize () {
    return _get(this.states, 'leftPanel.fiftyPercentWidth') ? '50%' : '350px'
  },
  // Func@panelSize,

  // Func@isLandingMode
  /**
   * Check DND mode
   */
  isLandingMode () {
    return landingPageMode().get()
  },
  // Func@isLandingMode
}

// Vue component syntax
export default {
  name,
  data,
  methods,
  computed,
  components
}
