// Services
import { globalstates } from '@/services/states/states.service'

// Library
import {
  get as _get
} from 'lodash-es'

export default {
  active: 'lines',
  sections: [
    {
      id: 'settings',
      title: 't_general-settings_',
      component: 'settings-general-modifier'
    },
    {
      id: 'blocks',
      title: 't_select-block_',
      component: 'structure-library'
    },
    {
      id: 'saved-blocks',
      title: 't_saved-blocks-title_',
      component: 'structure-blocks'
    }
  ],
  leftPanel: _get(globalstates, 'leftPanel', {})
}
