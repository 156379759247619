// Services
import { globalstates } from '@/services/states/states.service'

// Config files
import mjBodyConf from '@/assets/config/mjml-components/mj-body.conf.json'

// Library
import {
  get as _get
} from 'lodash-es'

export default {
  intialized: false,
  loadingBlocks: false,
  htmlLoaded: false,
  fromHistory: false,
  displayStates: false,
  resize: false,
  isMobile: false,
  mobileSizeFallBack: 940,
  email: '<span>No EMAIL generated by MJML</span>',
  mjBodyDefaultAttrib: null,
  gabaritName: null,
  selectedGabaritId: null,
  template: {
    tagName: 'mjml',
    attributes: {},
    children: [
      {
        tagName: 'mj-body',
        attributes: _get(mjBodyConf, 'attributes.default', {}),
        children: _get(globalstates, 'workspace.rows', [])
      },
      {
        tagName: 'mj-head',
        attributes: {},
        children: []
      }
    ]
  }
}
